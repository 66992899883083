import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Spin, message } from "antd";
import { User } from "../../../auth/authService";
import { usePostEditUserProfile } from "../../../services/userServices";
import { useTranslation } from "react-i18next";
import "./EditProfileModal.scss";
import { UserOutlined } from "@ant-design/icons";

interface EditProfileModalProps {
    visible: boolean;
    user: User | null;
    token: string;
    onCancel: () => void;
    onSave: (updatedProfile: User) => void;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
    visible,
    user,
    token,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const { refetch } = usePostEditUserProfile();

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
        }
    }, [user]);

    const handleSave = async () => {
        if (!user) {
            message.error(t("EditProfile.NoUserSelected"));
            return;
        }

        setLoading(true);

        try {
            await refetch({
                data: {
                    given_name: firstName,
                    family_name: lastName,
                },
                headers: {
                    channel: "w",
                    authorization: `Bearer ${token}`,
                },
            });

            message.success(t("EditProfile.SuccessMessage"));
            onSave({
                ...user,
                firstName,
                lastName,
            });
        } catch (err) {
            message.error(t("EditProfile.FailureMessage"));
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (visible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [visible]);
    return (
        <Modal
            getContainer={false}
            modalRender={(modal) => (
                <div
                    style={{
                        borderRadius: "14px",
                        overflow: "hidden",
                        padding: "0 !important",
                    }}
                >
                    {modal}
                </div>
            )}
            open={visible}
            onCancel={onCancel}
            footer={null}
            centered
            className="editProfileModal"
        >
            <div className="editProfile">
                <h2 className="signInText">{t("EditProfile.EditProfile")}</h2>
                <form>
                    <div className="form-group">
                        <div className="input-group">
                            <span>{t("SignUp.FirstName")}</span>
                            <Input
                                type="text"
                                placeholder={t("SignUp.EnterFirstName")}
                                className="InputBoxes"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                prefix={<UserOutlined className="icon" />}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span>{t("SignUp.LastName")}</span>
                            <Input
                                type="text"
                                className="InputBoxes"
                                placeholder={t("SignUp.EnterLastName")}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                prefix={<UserOutlined className="icon" />}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <span>{t("SignUp.email")}</span>
                            <Input
                                type="email"
                                className="InputBoxes"
                                value={email}
                                disabled
                                placeholder="Email (Disabled)"
                                prefix={
                                    <img src="/images/apts/icons/email_svg.svg" alt="email-icon" />
                                }
                            />
                        </div>
                    </div>
                    <Button
                        className="apply-button"
                        type="primary"
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? <Spin size="small" /> : t("settings.Apply")}
                    </Button>
                </form>
            </div>
        </Modal>
    );
};

export default EditProfileModal;
