export interface BaseParam {
    language?: string;
    channel?: string | 'W' | 'MA' | 'MI';//Channel (W - WebSite , MA - Android Mobile , MI - IOS Mobile)
    pageSections?: string;
    section?: string | 'H';
}

export interface ContactUsForm {
    name?: string;
    companyName?: string;
    emirateID?: number;
    phoneNumber?: string;
    email?: string;
}
export interface ContactUsFormPage {
    firstName?: string;
    lastName?: string;
    email?: string;
    subject?: number;
    message?: string;
}
export interface NewsLetter {
    email?: string;
   sourceId?:any;
   flag?:any;
   preferenceType?:any;
   EmailPreferenceRequest?:any
}
export interface ReportForm {
    Id: number;
    catID: number;
    text: string;
}

export interface ReportCategory {
    id: number;
    name: string;
}

export interface ReportCategoryResponse {
    result: ReportCategory[];
    errorMessage: string | null;
}

export interface Image {
    name: string;
    extension: string;
    url: string;
}

export enum ImageSize {
    Small = 'thumbnail',
    Medium = 'medium',
    Large = 'large'
}

export interface ClickAction {
    Id: number;
    click: string | 'C' | 'E' | 'W' | 'S';
    timestamp?: number;
    sourceId?:any;
    sourceType?:string
}

export interface Size {
    sqmt: number;
    sqrd: number;
    sqft: number;
}
export interface Feature {
    id: number;
    name: string;
    status: boolean;
    iconUrl: string;
}
export interface Nearby {
    id: number;
    name: string;
    status: boolean;
    iconUrl: string;
}
export interface Price {
    AED: number;
    EUR: number;
    GBP: number;
    SAR: number;
    PKR: number;
    USD: number;
    INR: number;
    RUB: number;
    CNY: number;
}
export interface Area {
    id: number;
    name: string;
    image?: string;
}
export interface Emirate {
    id: number;
    name: string;
    image?: string;
}
export interface BreadcrumbItem {
    name: string;
    value: string;
}
export interface Location {
    area: Area;
    emirate: Emirate;
    location_description?: string;
    latitude: string;
    longitude: string;
    breadcrumbs?: BreadcrumbItem[];
}

export interface favoriteBlog {
    Id: number;
    flag:number
 
}