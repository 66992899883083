import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from './authConfig';
import { AuthProvider } from './AuthProvider';
import { Auth0Client } from '@auth0/auth0-spa-js';

export const auth0Client = new Auth0Client({
    domain: authConfig.domain,
    clientId: authConfig.clientId,
    useRefreshTokens: true,
    cacheLocation: "localstorage",
    authorizationParams: {
        redirect_uri: authConfig.redirectUri,
        audience: authConfig.audience,
        scope: "offline_access",
    }
    // Other options as needed
});
export const Auth0CustomProvider = ({ children }: { children: React.ReactNode }) => {
    const onRedirectCallback = (appState?: { returnTo?: string }) => {
        window.history.replaceState({}, document.title, appState?.returnTo || '/');

    };




    return (
        <Auth0Provider
            domain={authConfig.domain}
            clientId={authConfig.clientId}
            authorizationParams={{
                redirect_uri: authConfig.redirectUri,
                audience: authConfig.audience,
                scope: "offline_access",
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            <AuthProvider>
                {children}
            </AuthProvider>
        </Auth0Provider >

    );
};
