import React, { useState, useEffect } from "react";
import "./RentOrSell.scss";
import { useTranslation } from "react-i18next";
import { Buy_B, Buy_Word, Rent_R, Rent_Word } from "../../RentOrSellConstant/constants";
interface Props {
    setFilterParams: (params: any) => any;
    agent: any;
}
export default function RentSellFilter({ setFilterParams, agent }: Props) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<string | null>(null);
    const initializeDealType = async () => {
        if (agent && agent.dealType) {
            const dealType = agent.dealType === Rent_R ? Rent_Word : Buy_Word;
            setSelected(dealType);
            setFilterParams((prevParams: any) => ({
                ...prevParams,
                rentOrSell: agent.dealType,
            }));
        }
    };
    useEffect(() => {
        initializeDealType();
    }, [agent]);
    const handleClick = (type: string) => {
        setSelected(type);
        setFilterParams((prevParams: any) => ({
            ...prevParams,
            rentOrSell: type === Rent_Word ? Rent_R : Buy_B,
        }));
    };

    if (!selected) {
        return null;
    }
    return (
        <div className="RentOrSell">
 <div className="btns">
            <button
                className={`RentBtn ${selected === Rent_Word ? "active" : ""}`}
                onClick={() => handleClick(Rent_Word)}
            >
                {t("popularInUAE.Rent")}
            </button>
            <button
                className={`BuyBtn ${selected === Buy_Word ? "active" : ""}`}
                onClick={() => handleClick(Buy_Word)}
            >
                {t("popularInUAE.Buy")}
            </button>
        </div>
        </div>
       
    );
}
