import './apartments.scss';
import ApartmentCard from '../apartmentCard/apartmentCard';
import { PropertyDTO } from '../../helpers/types/property';
import { useAuthContext } from '../../auth/AuthProvider';
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination } from 'swiper/modules';
import ApartmentCardAi from '../HousezAi/aiCards';
import CustomArrow from './customArrow';
import React, { useEffect, useState } from 'react';
import { Swiper as SwiperType } from 'swiper';
import ShareModal from '../Modals/ShareModal/ShareModal';
import { Modal } from 'antd';
import Login from "../../pages/user/login/login";
import i18n from '../../i18n';

interface ApartmentS {
    apartments: any;
    className?: string;
    rentalFrequency?: string;
    onPropertyClick?: (propID: string | number) => void;
    onFavClick?: () => void;

    apartType?: string;
    setIsModalVisible?: any
}

export default function Apartments({ setIsModalVisible, apartType, apartments, className = 'not-all', rentalFrequency, onPropertyClick, onFavClick }: ApartmentS) {

    const [selectedMessage, setSelectedMessage] = useState<string | null>(null);
    const [propertId, setPropertyId] = useState<number>()
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);
    const showUserModal = () => setIsUserModalVisible(!isUserModalVisible);
    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };
    const { authToken, isLoading, isAuthenticated } = useAuthContext();
    const swiperRef = React.useRef<SwiperType | null>(null);
    const [showArrows, setShowArrows] = useState(false);

    useEffect(() => {
        if (swiperRef.current) {
            const updateArrowsVisibility = () => {
                const swiper = swiperRef.current;
                if (swiper && swiper.params && swiper.params.slidesPerView !== undefined) {
                    const { slidesPerView } = swiper.params;
                    const totalSlides = apartments && apartments.length;

                    setShowArrows(totalSlides > 1 && totalSlides > slidesPerView);
                }
            };

            updateArrowsVisibility();
            window.addEventListener('resize', updateArrowsVisibility);

            return () => {
                window.removeEventListener('resize', updateArrowsVisibility);
            };
        }
    }, [apartments, swiperRef.current]);
    return (
        <div className={`${apartType === 'Ai' ? 'apartments-ai' : 'apartments'} ${className}`}>
            {apartType === 'Ai' ? (
                <>
                    {showArrows && (
                        <>
                            <CustomArrow direction="prev" onClick={() => swiperRef.current?.slidePrev()} className="custom-prev" />
                            <CustomArrow direction="next" onClick={() => swiperRef.current?.slideNext()} className="custom-next" />
                        </>
                    )}

                    <Swiper
                        key={i18n.dir()}
                        spaceBetween={10}
                        modules={[Navigation]}

                        autoplay={false}
                        breakpoints={{
                            420: { slidesPerView: 1 },
                            640: { slidesPerView: Math.min(2, apartments.length) },
                            768: { slidesPerView: Math.min(3, apartments.length) },
                            1024: { slidesPerView: Math.min(3, apartments.length) },
                            1200: { slidesPerView: Math.min(4, apartments.length) },
                        }}
                        onSwiper={(swiper: SwiperType) => {
                            swiperRef.current = swiper;
                        }}
                        onSlideChange={() => {
                            if (swiperRef.current && swiperRef.current.params && swiperRef.current.params.slidesPerView !== undefined) {
                                const { slidesPerView } = swiperRef.current.params;
                                const totalSlides = apartments.length;
                                setShowArrows(totalSlides > 1 && totalSlides > slidesPerView);
                            }
                        }}
                    >
                        {apartments?.map((item: any, idx: any) => {
                            if (item) {
                                return (
                                    <SwiperSlide key={idx}>
                                        <ApartmentCardAi
                                            property={{ ...item, rentalFrequency }}
                                            authToken={authToken ?? ""}
                                            onPropertyClick={onPropertyClick}
                                            setIsModalVisible={setIsModalVisible}
                                            setIsShareModalVisible={setIsShareModalVisible}
                                            showUserModal={showUserModal}
                                            isAuthenticated={isAuthenticated}
                                            setSelectedMessage={setSelectedMessage}
                                            setPropertyId={setPropertyId}
                                        />
                                    </SwiperSlide>

                                );
                            }
                        })}
                    </Swiper>



                    <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={selectedMessage} id={propertId} />
                    {isUserModalVisible && (
                        <Modal
                            getContainer={false}
                            open={isUserModalVisible}
                            onCancel={showUserModal}
                            footer={null}
                            centered
                            loading={isLoading}
                            modalRender={(modal) => (
                                <div className="modalCard" >
                                    {modal}
                                </div>
                            )}
                            style={{ padding: "0px" }}
                        >
                            <Login />
                        </Modal>
                    )}

                    {/* Arrows positioned inside the swiper container */}

                </>


            ) : (
                <div className={`apartments ${className}`}>
                    {
                        apartments?.map((item: any, idx: any) => {
                            if (item)
                                return (
                                    <div className="item" key={idx}>
                                        <ApartmentCard
                                            property={{ ...item, rentalFrequency }}
                                            // authToken={authToken ?? ""}
                                            onPropertyClick={onPropertyClick}
                                            onFavClick={onFavClick}
                                        />
                                    </div>
                                )
                        })
                    }
                </div>
            )}

        </div>
    );
}
