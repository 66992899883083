import './companyCard.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertObjectToImageString } from '../../helpers/helpers';
import { CompanyDTO } from '../../helpers/types/company';


export default function CompanyCard({ company }: { company: CompanyDTO }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="company-card"
        onClick={() => navigate(`/companyProfile/${company.id}`)}
        >
            <div className="img">
                <div className="company-img-container">
                    <img className="company-img" src={convertObjectToImageString(company?.logo) ?? "/images/Logo-Ex.png"} />
                </div>
                <span className="name">{company?.name ?? "Company Name"}</span>
                <span className="address">{company?.emirate ?? "Company Address"}</span>
            </div>
            <div className="details">
                {(company?.emirate == "Dubai" || company?.emirate == "دبي") && (
                    <div className="field">
                        <span className="title">{t("companies.ORN")} </span>
                        <span className="value">{company?.ORN ?? "#"}</span>
                    </div>
                )}
                {(company?.emirate == "Abu Dhabi" || company?.emirate == "أبو ظبي") && (
                    <div className="field">
                        <span className="title">{t("companies.ADM")} </span>
                        <span className="value">{company?.adm ?? "#"}</span>
                    </div>
                )}
                <div className="field">
                    <span className="title">{t("companies.Agents")} </span>
                    <span className="value">{company.staffCount ?? 0}</span>
                </div>
                <div className="field">
                    <span className="title">{t("companies.Properties")} </span>
                    <span className="value">{company.propCount ?? 0}</span>
                </div>
            </div>
            <button className="open" onClick={() => navigate(`/companyProfile/${company.id}`)}>{t('companies.OpenProfile')}</button>
        </div>
    )
}