import { useEffect, useState } from "react";
import './agentProfile.scss';
import { useParams } from "react-router-dom";
import Apartments from "../../components/apartments/apartments";
import { useLanguage } from "../../context/LanguageContext";
import { AgentFilterParam } from "../../helpers/types/filter";
import { useGetAgent, useGetAgentReportReason, usePostAgentClick, usePostAgentReport } from "../../services/agentsServices";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProperties } from "../../services/propertiesServices";
import FilterCountDisplay from "../../components/filters/FilterCountDisplay/FilterCountDisplay";
import ErrorComponent from "../../components/errorComponent/error";
import { PuffLoader } from "react-spinners";
import call from '../../assets/icons/call.svg';
import email from '../../assets/icons/email.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';
import share from '../../assets/icons/share.svg';
import languageIcon from '../../assets/icons/language.svg';
import nationalityIcon from '../../assets/icons/nationality.svg';
import workIcon from '../../assets/icons/work.svg';
import BRNIcon from '../../assets/icons/BRN.svg';
import { convertObjectToImageString, whatsAppME } from "../../helpers/helpers";
import MenuDropdown from "../../components/PropertyComponents/MenuDropdownProps/MenuDropdownProps";
import ReportModal from "../../components/Modals/ReportModal/ReportModal";
import { ClickAction, ReportForm } from "../../helpers/types/general";
import SelectTab from "../../components/filters/FilterTypes/SelectTab";
import RentSellFilter from "../../components/filters/FilterRentOrSell/RentOrSell";
import ShareModal from "../../components/Modals/ShareModal/ShareModal";
import { useSettings } from "../../context/SettingsContext";
import { useNativeShare } from "../../components/Modals/ShareModal/nativeShare";
import { Rent_R } from "../../components/RentOrSellConstant/constants";
import EmailModal from "../../components/emailPopup/emailModal";
import { Grid, Spin } from "antd";

export default function AgentProfile() {
    const id = useParams().id;
    const screens = Grid.useBreakpoint();
    const { agent: agentInfo, agentLoading, agentError, refetch } = useGetAgent(Number(id))
    const agent = agentInfo?.result[0];
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
            setIsMobile(true);
        }
    }, []);
    const handleNativeShare = useNativeShare(agent?.shareMsg);

    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
        setFormClickRequest({
            Id: Number(id),
            click: 'E',
            sourceType: 'A'
        });

    };
    const handleShareClick = () => {
        if (isMobile) {
            handleNativeShare()
        }
        else {
            setIsShareModalVisible(true);
        }
    };

    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };
    const { t } = useTranslation();
    const { language } = useLanguage();
    const navigate = useNavigate();

    const [formClickRequest, setFormClickRequest] = useState<ClickAction>({
        Id: Number(id),
        click: "",
        sourceType: ''
    });

    const [isReportModalVisible, setIsReportModalVisible] = useState(false);
    const { reasons: reportReasons } = useGetAgentReportReason({ language: language.toUpperCase() });
    const [formReportRequest, setFormReportRequest] = useState<ReportForm>({
        Id: Number(id),
        catID: 1,
        text: ""
    });
    const { responseData } = usePostAgentReport(formReportRequest);
    const { sort } = useSettings();

    const [currentPage, setCurrentPage] = useState(1);

    const [filterParams, setFilterParams] = useState<AgentFilterParam>({
        agentIDs: [Number(id)],
        // orderByMode: 'LTH',
        orderByMode: `${sort ?? "price_low_to_high"}`,

        rentOrSell: agent?.dealType,
        page: currentPage - 1,
        size: 10,
        language: language.toUpperCase(),
        channel: 'W',
    });
    const { properties: data, loading, error } = useGetProperties(filterParams);
    const [properties, setProperties] = useState(data?.result || []);
    const totalItems = data?.pageInfo?.totalElements || 0;

    useEffect(() => {
        setFilterParams((prevParams) => ({
            ...prevParams,
            rentOrSell: agent?.dealType,
            orderByMode: `${sort ?? "price_low_to_high"}`
        }));
    }, [agent, sort]);


    // const handleShareClick = () => {
    //     setFormClickRequest({
    //         Id: Number(id),
    //         click: 'S',
    //         timestamp: new Date().getTime()
    //     });
    //     const shareData = {
    //         title: 'Check out this profile!',
    //         text: 'Look at this profile I found!',
    //         url: window.location.href,
    //     };

    //     if (navigator.share) {
    //         navigator.share(shareData)
    //             .then(() => console.log('Share successful!'))
    //             .catch((error) => console.log('Error sharing:', error));
    //     } else {
    //         // Fallback for unsupported browsers
    //         const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareData.text)}&url=${encodeURIComponent(shareData.url)}`;
    //         window.open(twitterUrl, '_blank');
    //     }
    // };

    const handleCallClick = async () => {
        window.location.href = `tel:${agent?.mobile}`;
        setFormClickRequest({
            Id: Number(id),
            click: 'M',
            sourceType: 'A'
        });
    };

    const { responseData: postClickResponse, refetch: refetchClick } = usePostAgentClick(formClickRequest);

    const handleWhatsAppClick = async () => {
        whatsAppME(agent?.whatsAppNumber || "", agent?.whatsAppMsg ?? "");
        setFormClickRequest({
            Id: Number(id),
            click: 'W',
            sourceType: 'A'
        });
    };
    useEffect(() => {
        if (formClickRequest.click && formClickRequest.sourceType) {
            refetchClick();
        }
    }, [formClickRequest]);
    const handleReportClick = () => {
        setIsReportModalVisible(true);
    };

    const handleCloseReportModal = () => {
        setIsReportModalVisible(false);
    };
    const isHasNextPage = data?.pageInfo?.hasNext
    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    }
    useEffect(() => {
        setFilterParams((prevParams) => ({
            ...prevParams,
            page: currentPage - 1,
        }));
    }, [currentPage]);

    useEffect(() => {
        if (data?.result) {
            setProperties((prevProperties: any) => [...prevProperties, ...data.result]);
        }
    }, [data]);
    return (
        <div className={`agent-profile`}>
            <div className="agent-header">
                <div>
                    <picture>
                        <source
                            media="(max-width: 768px)"
                            srcSet="https://housez.s3.ap-south-1.amazonaws.com/Website/Agent/Agent+Profile+Background-+Mobile+view.jpeg"
                        />
                        <img
                            src="https://housez.s3.ap-south-1.amazonaws.com/Website/Agent/Agent+Profile+Background-+Mobile+view.jpeg"
                            alt="Agent Background"
                            className="header-background"
                        />
                    </picture>
                    <div className="contact-item contact-item-small drop-down-header" >
                        <MenuDropdown
                            isMobile={true}
                            onReportClick={handleReportClick}
                            onShareClick={handleShareClick}
                        />
                    </div>
                </div>
                <div className="agent-details container">
                    <div className="personal-hide"></div>
                    <div className="personal">
                        <div className="agent-photo-container">
                            {agent?.image ? (
                                <img className="agent-photo" src={convertObjectToImageString(agent.image)} alt="Agent Photo" />
                            ) : (
                                <div className="agent-photo-placeholder">
                                    <span>{agent?.name?.charAt(0).toUpperCase() ?? Rent_R}</span>
                                </div>
                            )}
                        </div>
                        <h1>{agent?.name ?? ""}</h1>
                        <p>{agent?.position ?? ""}</p>
                    </div>
                    <div className="contact">
                        <a className="contact-item" onClick={handleCallClick}>
                            <img src={call} alt="" />
                            <span>{t('common.call')}</span>
                        </a>
                        <a className="contact-item" onClick={showModal}>
                            <img src={email} alt="" />
                            <span>{t('common.email')}</span>
                        </a>
                        <div className="contact-item" onClick={handleWhatsAppClick}>
                            <img src={whatsapp} alt="" />
                            <span>{t('common.whatsApp')}</span>
                        </div>
                        {/* <div className="contact-item" onClick={handleShareClick}>
                            <img src={share} alt="" />
                            <span>{t('common.shareProfile')}</span>
                        </div> */}
                        {/* <div className="contact-item contact-item-small " >
                            <MenuDropdown
                                isMobile={false}
                                onReportClick={handleReportClick}
                                onShareClick={handleShareClick}
                            />
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="agent-content container">
                <div className="left-content">
                    <div className="brokerage-section">
                        <h3>{t('AgentProfile.Brokerage')}</h3>
                        <img src={convertObjectToImageString(agent?.company.logo)} alt="logo" className="brokerage-logo" />
                        <p>{agent?.company.name ?? ""}</p>
                        <a onClick={() => navigate(`/companyProfile/${agent?.company.id}`)}>{t('FindYourAgent.OpenProfile')}</a>
                    </div>

                    <div className="info-section">
                        <h3>{t('AgentProfile.PersonalInformation')}</h3>
                        <div className="info-item">
                            <img src={languageIcon} alt="Languages" />
                            <div className="info-details">
                                <span>{t('AgentProfile.Languages')}</span>
                                <span>{agent?.lang?.length ? agent.lang.join(", ") : "-"}</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <img src={workIcon} alt="Start Working Year" />
                            <div className="info-details">
                                <span>{t('AgentProfile.StartWorkingYear')}</span>

                                <span>{agent?.workingYear ?? ""}</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <img src={nationalityIcon} alt="Nationality" />
                            <div className="info-details">
                                <span>{t('AgentProfile.Nationality')}</span>
                                <span>{agent?.nationality ?? ""}</span>
                            </div>
                        </div>
                        {(agent?.emairtName == "Dubai" || agent?.emairtName == "دبي") && (
                            <div className="info-item">
                                <img src={BRNIcon} alt="BRN" />
                                <div className="info-details">
                                    <span>{t('AgentProfile.BRN')}</span>
                                    <span>{agent?.brn ?? ""}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="description-section">
                        <h3>{t('AgentProfile.Description')}</h3>
                        <p>
                            {agent?.description ?? ""}
                        </p>
                        <div className="areas-container">
                            <div className="areas-label">{t('AgentProfile.Areas')}:</div>
                            <span className="areas-list">
                                {agent?.area?.map((item, index) => (
                                    <div key={index}>{item}</div>
                                )) || "N/A"}
                            </span>
                        </div>

                        <p className="properties-section">
                            {t('AgentProfile.Properties')}: <span>{agent?.totalProp}</span>
                        </p>
                    </div>
                </div>

                <div className="right-content">
                    <div className="props">
                        <div className="apts">
                            <FilterCountDisplay
                                title={t('AgentProfile.MyProperties')}
                                entityName={t('AgentProfile.Properties')}
                                totalItems={totalItems}
                                showPages={false}
                                setFilterParams={setFilterParams}
                                agent={agent}
                                showSortWithRentSellFilter={true}
                            />

                            {loading && properties.length === 0 &&
                                <div className="loader" >
                                    <PuffLoader color="#54B0ED" loading={loading} />
                                </div>
                            }
                            {error && <ErrorComponent message={t('AgentProfile.ErrorLoadingProperties')} />}
                            {properties?.length > 0 && (
                                <>
                                    <Apartments apartments={properties} />

                                    {isHasNextPage && (
                                        loading ? (
                                            <Spin
                                                style={{
                                                    margin: '0 auto',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: screens.xs ? '30px 0px 30px 0px' : '80px 0px 77px 0px',
                                                }}
                                            />
                                        ) :
                                            (
                                                <button
                                                    className="seeMoreBtn"
                                                    onClick={handleLoadMore}

                                                >
                                                    {t('BlogsAndNews.SeeMore')}
                                                </button>
                                            )

                                    )}
                                </>
                            )}
                            {!loading && properties?.length === 0 && (
                                <div className="no-properties">{t('AgentProfile.NoPropertiesFound')}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ReportModal
                reportReasons={reportReasons?.result || []}
                formReportRequest={formReportRequest}
                setFormReportRequest={setFormReportRequest}
                isVisible={isReportModalVisible}
                onClose={handleCloseReportModal}
                title={t('AgentProfile.ReportTitle')}
                description={t('AgentProfile.ReportDescription')}
            />
            <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={agent?.shareMsg} />
            <EmailModal sectionNameClicked="email" msg={agent?.agentEmailMsg} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />

        </div >
    )
}