import React from 'react';
import './mainDescription.scss';
import { formatTimeAgo } from '../../../helpers/helpers';
import ActionButtons from '../actionButtons/actionButtons';
import { useTranslation } from 'react-i18next';
import { PropertyDTO } from '../../../helpers/types/property';
import { useSettings } from '../../../context/SettingsContext';

interface MainDescriptionProps {
    property?: PropertyDTO;
    price: { value: number | undefined; per: string; };
    isFavorited?: boolean;
    onFavoriteToggle?: (favorited: boolean) => void;
    onReportClick: () => void;
    setIsUserModalVisible?: any
    showUserModal?: any
    isAuthenticated?: any
}

const MainDescription: React.FC<MainDescriptionProps> = ({ isAuthenticated, showUserModal, setIsUserModalVisible, property, price, isFavorited = false, onFavoriteToggle, onReportClick }) => {
    const { t } = useTranslation();
    const { currency } = useSettings();
    const timeAgo = property?.publishDate ? formatTimeAgo(property.publishDate) : '';
    return (
        <div className="main-description">
            <div className="primary">
                <div>
                    <span className="listed-from">{t("property.ListedIn")} {timeAgo}</span>
                    <span className="price">
                        {price.value
                            ? (price.value.toString().length > 15
                                ? price.value.toFixed(3).toLocaleString()
                                : price.value.toLocaleString(undefined, { maximumFractionDigits: 3 }))
                            : '0'} {currency ?? t('common.AED')} {price.per}
                    </span>
                </div>
                <ActionButtons
                    isMobile={false}
                    latitude={property?.location.latitude}
                    longitude={property?.location.longitude}
                    isFavorited={isFavorited}
                    onFavoriteToggle={onFavoriteToggle}
                    onReportClick={onReportClick}
                    property={property}
                    setIsUserModalVisible={setIsUserModalVisible}
                    showUserModal={showUserModal}
                />
            </div>

            <div className="secondary">
                <div className="location">
                    <img src={'/images/apts/icons/location-pin-details.svg'} alt="Location" />
                    <span className="title">{property?.location?.location_description ?? "Jade Tower, Majan, Dubai"}</span>
                </div>
                <span className="location-features">{property?.title ?? "INVESTOR DEAL | 20 % HUGE DISCOUNT | FULLY LUXURY"}</span>
            </div>
        </div>
    );
};

export default MainDescription;
