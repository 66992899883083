import './favorite.scss';
import Apartments from "../../components/apartments/apartments";
import { PuffLoader } from "react-spinners";
import { useLanguage } from "../../context/LanguageContext";
import { PropertyFilterParam } from "../../helpers/types/filter";
import { useGetProperties } from "../../services/propertiesServices";
import PaginationComponent from "../../components/Pagination/PaginationComponent";
import FilterCountDisplay from "../../components/filters/FilterCountDisplay/FilterCountDisplay";
import { useTranslation } from "react-i18next";
import { useGetFrequencies } from "../../services/filtersServices";
import emptyData from "../../assets/images/exploreEmpty.svg";
import { useGetMyFavouritesProperties } from '../../services/userServices';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../auth/AuthProvider';
import { useFilterState } from '../explore/filterState';

export default function Favorite() {
    const { language } = useLanguage();
    const { t } = useTranslation();
    const itemsPerPage = 9;

    const initialFilterParams: PropertyFilterParam = {
        page: 0,
        size: itemsPerPage,
        language: language.toUpperCase(),
    };

    const { authToken } = useAuthContext();
    const access_token = localStorage.getItem('access_token')


    const [filterParams, updateFilterParams] = useFilterState(initialFilterParams);
    const { properties: data, loading, refetch: refetchFavourites } = useGetMyFavouritesProperties(filterParams, authToken ?? access_token ?? "");
    // const properties = data?.result;
    const properties = data?.result?.map((property: any) => ({
        ...property,
        isFavorite: true,
    })) || [];


    const { frequencies } = useGetFrequencies({ language: language.toUpperCase() });
    const totalItems = data?.pageInfo?.totalElements || 0;
    const rentalFrequencyName = frequencies?.result?.find((frequency: any) => filterParams?.frequency?.includes(frequency.id))?.name || 'Yearly';

    const handlePageChange = (page: number) => {
        updateFilterParams({ page: page - 1 });
    };

    return (
        <div className="explore">
            {loading ? (
                <div className="loader">
                    <PuffLoader color="#54B0ED" loading={loading} />
                </div>
            ) : (
                <div className="props">
                    <div className="apts">
                        <FilterCountDisplay
                            title={t('property.MyFavourites')}
                            entityName={t('common.Properties')}
                            currentPage={filterParams.page + 1}
                            itemsPerPage={filterParams.size}
                            totalItems={totalItems}
                            showPages={false}
                        />
                        {properties?.length > 0 ? (
                            <>
                                <Apartments apartments={properties} rentalFrequency={rentalFrequencyName} onFavClick={refetchFavourites} />
                                <PaginationComponent
                                    current={filterParams.page}
                                    total={totalItems}
                                    pageSize={filterParams.size}
                                    onChange={handlePageChange}
                                />
                            </>
                        ) : (
                            <div className='emptyData'>
                                <img style={{ maxWidth: "100%" }} src="/images/apts/icons/noData.svg" alt="No data" />
                                <p className='noMatchText'>{t("searches.noLiked")}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
