import { useAuth0 } from '@auth0/auth0-react';
import { Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../pages/user/login/login';
import { useAuth } from './useAuth';
import { useAuthContext } from './AuthProvider';

interface PrivateRouteProps {
    component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
    const { isAuthenticated, isLoading, user } = useAuthContext();
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);
    const showUserModal = () => setIsUserModalVisible(!isUserModalVisible);

    console.log("User with Role: ", user, isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            setIsUserModalVisible(true)
        } else {
            setIsUserModalVisible(false)

        }

    }, [isAuthenticated])
    if (isLoading) return <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px', color: "green !important" }}
    />


    return isAuthenticated && user ? (
        <Component />
    ) : (
        <Modal
            getContainer={false}
            open={isUserModalVisible}
            onCancel={showUserModal}
            footer={null}
            centered
            loading={isLoading}
            modalRender={(modal) => (
                <div className="modalCard" >
                    {modal}
                </div>
            )}
            style={{ padding: "0px" }}
        >
            <Login />
        </Modal>

        // <Navigate to="/login" replace />
    );
};

export default PrivateRoute;
