import React, { useEffect, useState } from 'react';
import './actionButtons.scss';
import MenuDropdown from '../MenuDropdownProps/MenuDropdownProps';
import CustomTooltip from '../../Tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import ShareModal from '../../Modals/ShareModal/ShareModal';
import { useNativeShare } from '../../Modals/ShareModal/nativeShare';
import { useAuthContext } from '../../../auth/AuthProvider';
import { useAddToFavourites, useDeleteFavourites } from '../../../services/userServices';
import { notification } from 'antd';
interface ActionButtonsProps {
    isMobile: boolean;
    latitude?: string;
    longitude?: string;
    isFavorited?: boolean;
    onFavoriteToggle?: (favorited: boolean) => void;
    onReportClick: () => void;
    property?: any
    setIsUserModalVisible?: any
    showUserModal?: any
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ showUserModal, setIsUserModalVisible, isMobile = false, latitude, longitude, isFavorited, onFavoriteToggle, onReportClick, property }) => {
    const [isMobileNative, setIsMobileNative] = useState(false);
    const { isAuthenticated, isLoading, authToken } = useAuthContext();
    const { refetch: AddToFavourites } = useAddToFavourites();
    const { refetch: DeleteFavourites } = useDeleteFavourites();
    const [isFavorite, setIsFavorite] = useState(isFavorited);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const { t } = useTranslation()
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
            setIsMobileNative(true);
        }
    }, []);

    const handleNativeShare = useNativeShare(property?.shareMsg);
    const handleMapClick = () => {
        if (latitude && longitude) {
            const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            window.open(googleMapsUrl, '_blank');
        } else {
            alert('Location is not available');
        }
    };

    const handleFavoriteClick = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
        if (!isAuthenticated) {
            showUserModal();
        } else {
            if (!isFavorite) {
                AddToFavourites({
                    data: {
                        Id: property.ID,
                    },
                    headers: {
                        channel: "w",
                        authorization: `Bearer ${authToken}`,
                    },
                })
                    .then(() => {
                        setIsFavorite(true);
                    })
                    .catch((error) => {
                        notification.error({
                            message: 'Error',
                            description: t("property.Failedtofavorites"),
                        });
                    });
            } else {
                DeleteFavourites({
                    data: {
                        Id: property.ID,
                    },
                    headers: {
                        channel: "w",
                        authorization: `Bearer ${authToken}`,
                    },
                })
                    .then(() => {
                        setIsFavorite(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: 'Error',
                            description: t("property.Failedtoremove"),
                        });
                    });
            }
        }
    };

    const handleReportClick = () => {
        if (onReportClick) onReportClick();
    };

    const handleShareClick = () => {
        if (isMobileNative) {
            handleNativeShare()
        }
        else {
            setIsShareModalVisible(true);
        }
    };

    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };

    return (
        <div className="action-btns">
            {latitude && longitude && (
                <CustomTooltip title={t("common.ViewonMap")}>
                    <img
                        src={isMobile ? '/images/map_svgrepo.com-white.svg' : '/images/map_svgrepo.com.svg'}
                        alt="Map"
                        onClick={handleMapClick}
                        style={{ cursor: 'pointer' }}
                    />
                </CustomTooltip>
            )}
            <CustomTooltip title={isFavorite ? t("common.RemovefromFavorites") : t("common.AddtoFavorites")}>
                <img
                    className={`favorite-btn ${isFavorite ? 'filled' : 'empty'}`}
                    src={isFavorite
                        ? (isMobile ? '/images/apts/icons/love-filled-white.svg' : '/images/apts/icons/love-svgrepo-com-filled-white.svg')
                        : (isMobile ? '/images/apts/icons/love-white.svg' : '/images/apts/icons/love-svgrepo-com-white.svg')}
                    alt="Favorite"
                    onClick={handleFavoriteClick}
                    style={{ cursor: 'pointer' }}
                />
            </CustomTooltip>
            <MenuDropdown
                isMobile={isMobile}
                onShareClick={handleShareClick}
                onReportClick={handleReportClick}
            />
            <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={property?.shareMsg} />

        </div>
    );
};

export default ActionButtons;
