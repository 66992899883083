import React, { useEffect, useState } from 'react';
import './infoSectionComponent.scss';
import { convertObjectToImageString } from '../../helpers/helpers';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import CustomArrow from '../apartments/customArrow';
import { Navigation, Pagination } from 'swiper/modules';
import CustomLayoutArrow from './layoutImageArrows';
import { Swiper as SwiperType } from 'swiper';
import { Grid } from 'antd';

export interface InfoItem {
    label: string;
    value?: string | number;
    iconUrl?: any;
}

interface InfoSectionComponentProps {
    title: string;
    items: InfoItem[];
    columns?: number;
    titleId?: any
}

const InfoSectionComponent: React.FC<InfoSectionComponentProps> = ({ titleId, title, items, columns = 2 }) => {
    const [dynamicColumns, setDynamicColumns] = useState(columns);
    const [isMobile, setIsMobile] = useState(false);
    const swiperRef = React.useRef<SwiperType | null>(null);
    const screen = Grid.useBreakpoint()
    const updateColumns = () => {
        if (window.innerWidth <= 450) {
            setDynamicColumns(1);
            setIsMobile(true);
        } else if (window.innerWidth <= 768) {
            setDynamicColumns(2);
            setIsMobile(false);
        } else {
            setDynamicColumns(columns);
            setIsMobile(false);
        }
    };

    useEffect(() => {
        updateColumns();
        window.addEventListener('resize', updateColumns);

        return () => {
            window.removeEventListener('resize', updateColumns);
        };
    }, [columns]);

    const totalItems = items.length;
    const remainder = totalItems % dynamicColumns === 0 ? dynamicColumns : totalItems % dynamicColumns;
    const lastRowStartIndex = totalItems - remainder;

    const isValidUrl = (value: string) => {
        try {
            new URL(value);
            return true;
        } catch (error) {
            return false;
        }
    };

    const hasIconUrl = items.some((item) => item.iconUrl);

    return (
        <div className="info-section-details"
            style={{
                maxWidth: hasIconUrl ? "867px" : "90%",
                width: hasIconUrl ? "100%" : '90%'
            }}
        >
            <span className="section-title">{title}</span>
            <div
                className={`items-grid ${columns <= 2 ? "small-grid" : ""}`}
                style={{
                    gridTemplateColumns: `repeat(${dynamicColumns}, 1fr)`,
                    gap: hasIconUrl ? "15px" : screen.xs ? "20px" : "60px",
                    rowGap: "16px"
                }}
            >
                {items.map((item, index) => {
                    const isLastRowItem = index >= lastRowStartIndex;
                    return (
                        <div key={index}
                            className={`info-item ${isLastRowItem || !item.value ? 'last-in-column' : ''}`}
                        >
                            {item.iconUrl && !isMobile && titleId === 'layout' ? (
                                <img
                                    src={typeof item.iconUrl === 'object' && item.iconUrl !== null
                                        ? convertObjectToImageString(item.iconUrl)
                                        : item.iconUrl}
                                    alt={item.label}
                                    className={typeof item.iconUrl === 'object' ? 'iconLayout' : 'icon'}
                                />
                            ) : (
                                titleId !== 'layout' && item.iconUrl && (
                                    <img
                                        src={typeof item.iconUrl === 'object' && item.iconUrl !== null
                                            ? convertObjectToImageString(item.iconUrl)
                                            : item.iconUrl}
                                        alt={item.label}
                                        className={typeof item.iconUrl === 'object' ? 'iconLayout' : 'icon'}
                                    />
                                )
                            )}
                            <span className="label">{item.label}</span>
                            {item.value && (
                                isValidUrl(item.value.toString()) ? (
                                    <a href={item.value.toString()} target="_blank" rel="noopener noreferrer" className="value link">
                                        View Link
                                    </a>
                                ) : (
                                    <span className="value">{item.value}</span>
                                )
                            )}
                        </div>
                    );
                })}
            </div>

            {isMobile && hasIconUrl && titleId === 'layout' && (
                <div className="swiper-container">
                    <CustomLayoutArrow direction="prev" onClick={() => { }} className="custom-prev" />
                    <CustomLayoutArrow direction="next" onClick={() => { }} className="custom-next" />
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        modules={[Navigation]}
                        loop
                        navigation={{
                            prevEl: '.custom-prev',
                            nextEl: '.custom-next',
                        }}
                        breakpoints={{
                            720: { slidesPerView: 1 },
                        }}
                        onSwiper={(swiper: SwiperType) => {
                            swiperRef.current = swiper;
                        }}
                        style={{ width: '100%', overflow: 'hidden', height: "228px", objectFit: "contain" }}
                    >
                        {items.map((item, index) => {
                            return (
                                <SwiperSlide key={index} style={{ width: '100%' }}>
                                    {item.iconUrl && (
                                        <div style={{
                                            width: '100%',
                                            maxWidth: '279px',
                                            height: '228px',
                                            borderRadius: '18px',
                                            margin: '0 auto',
                                            display: 'flex',
                                            justifyContent: "center",
                                            overflow: 'hidden',
                                        }}>
                                            <img
                                                src={typeof item.iconUrl === 'object' && item.iconUrl !== null
                                                    ? convertObjectToImageString(item.iconUrl)
                                                    : item.iconUrl}
                                                alt={item.label}
                                            />
                                        </div>

                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                </div>

            )}
        </div>
    );
};

export default InfoSectionComponent;
