import React, { useEffect, useState } from "react";
import { Modal, Input, Button, message } from "antd";
import { useAuthContext } from "../../../auth/AuthProvider";
import { MailOutlined } from '@ant-design/icons';
import './ResetPasswordModal.scss';
import { useTranslation } from "react-i18next";

interface ResetPasswordModalProps {
    visible: boolean;
    onClose: () => void;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ visible, onClose }) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [messageText, setMessageText] = useState<string | null>(null);

    const { forgotPassword } = useAuthContext();

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setMessageText(null);
        setLoading(true);

        try {
            const result = await forgotPassword(email);
            setMessageText(result);
            message.success(t("forgotPassword.PasswordresetLink"));
            onClose();
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to send password reset email');
            message.error(t("forgotPassword.Errorsending"));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setError(null);
        setMessageText(null);
        setLoading(false);
    }, []);
    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (visible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [visible]);
    return (
        <Modal
            getContainer={false}
            modalRender={(modal) => (
                <div style={{ maxWidth: "531px", marginBottom: "20px", marginTop: "20px", borderRadius: '14px', overflow: 'hidden', padding: "0 !important" }}>
                    {modal}
                </div>
            )}
            open={visible}
            onCancel={onClose}
            footer={null}
            title="Reset Password"
            centered
            className="reset-password-modal"
        >
            <h2 className='signInText'>{t("SignIn.Forgot")}</h2>
            <p className='EnterEmail'>
                {t("forgotPassword.Enteremailaddress")}<br />
                {t("forgotPassword.willbesent")}</p>
            {error && <p className="error">{error}</p>}
            {messageText && <p className="message">{messageText}</p>}

            <form onSubmit={handleResetPassword}>
                <div className="input-group">
                    <span > {t("SignUp.email")} </span>

                    <Input
                        type="email"
                        className='InputBoxes'
                        placeholder="yourname@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        prefix={<img src='/images/apts/icons/email_svg.svg' />}
                    />

                    {/* <MailOutlined className="icon" />
                    <input
                        type="email"
                        placeholder="yourname@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    /> */}
                </div>
                <button type="submit" className="login-button" disabled={loading}>
                    {loading ? t("forgotPassword.Sending") : t("forgotPassword.Continue")}
                    <img className='arrowSignIn' src='/images/apts/icons/signIn-Arrow.svg' />

                </button>
                {/* <p className="footer-text">
                        {t("forgotPassword.RememberPassword")} <a> {t("SignUp.SignIn")} </a>
                        </p> */}
            </form>
        </Modal>
    );
};

export default ResetPasswordModal;
