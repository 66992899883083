import React, { useEffect, useState } from 'react';
import CheckboxFilter from './FilterTypes/CheckboxFilter';
import RangeFilter from './FilterTypes/RangeFilter';
import TextSearchFilter from './FilterTypes/TextSearchFilter';
import './FilterContainer.scss';
import SelectFilter from './FilterTypes/SelectFilter';
import { useTranslation } from 'react-i18next';
import CheckboxGroupFilter from './FilterTypes/CheckboxGroupFilter';
import AdvancedFilter, { Section } from './FilterTypes/AdvancedFilter';
import MinMaxInput from './FilterTypes/inputs/MinMaxInput';
import LocationSearchFilter, { PlaceResult } from './FilterTypes/LocationSearchFilter';
import saveIcon from '../../assets/icons/filter/save.svg'
import resetIcon from '../../assets/icons/filter/reset.svg'

import searchIcon from '../../assets/icons/filter/search.svg'
import { useAuthContext } from '../../auth/AuthProvider';
import { Modal } from 'antd';
import Login from '../../pages/user/login/login';


export type FilterType = 'select' | 'checkbox' | 'range' | 'text' | 'checkboxGroup' | 'advanced' | 'minMax' | 'location';

export interface FilterConfig {
    type: FilterType;
    param: string;
    options?: { id: number | string; name: string }[];
    sections?: Section[];
    placeholder?: string;
    multiple?: boolean;
    value?: any;
    minPlaceholder?: string;
    maxPlaceholder?: string;
    minValue?: number;
    maxValue?: number;
    onChange?: (param: string, value: any) => void; // TODO update all callers
    className?: string;
    directQueryOnChange?: boolean;
}

interface FilterContainerProps {
    filtersConfig: FilterConfig[];
    onFilterChange: (filterParams: any) => void;
    onSearch?: (filterParams: any) => void;
    onSave?: (filterParams: any) => void;
    onReset?: (filterParams: any) => void;
    showSearchButton?: boolean;
    showSaveButton?: boolean;
    showResetButton?: boolean;
    setText?: any,
    loadingSuggestion?: boolean
}

const FilterContainer: React.FC<FilterContainerProps> = ({ loadingSuggestion, setText, filtersConfig, onFilterChange, onSearch, onSave, onReset, showSearchButton = true, showResetButton = true, showSaveButton = false }) => {
    const { t } = useTranslation();
    const [filterValues, setFilterValues] = useState<any>({});
    //user auth
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);
    const showUserModal = () => setIsUserModalVisible(!isUserModalVisible);

    const { isAuthenticated, isLoading, getAuthToken, user } = useAuthContext();
    const [authToken, setAuthToken] = useState<string | null>(null);


    const handleFilterChange = (param: string, value: any, directQueryOnChange = false) => {
        setFilterValues((prevValues: any) => ({
            ...prevValues,
            [param]: value,
        }));
        if (directQueryOnChange) onFilterChange({ [param]: value });
    };

    const handleApplayFilterChange = () => {
        onFilterChange(filterValues);
    }

    const handleSearch = () => {
        if (onSearch) onSearch(filterValues);
    };

    const handleSave = () => {
        if (!isAuthenticated) {
            showUserModal()
        }
        else {
            if (onSave) onSave(filterValues);
        }

    };

    const handleReset = () => {
        const event = new Event("resetButtonClick");
        document.dispatchEvent(event);
        setFilterValues({})
        if (onReset) {
            onReset(filterValues)
        }

    };


    return (
        <div className="filters-container">
            <div className="filters-l">
                {filtersConfig.map((filter) => {
                    switch (filter.type) {
                        case 'select':
                            return (
                                <SelectFilter
                                    key={filter.param}
                                    className={filter.className}
                                    options={filter.options || []}
                                    placeholder={filter.placeholder || ''}
                                    multiple={filter.multiple}
                                    onChange={(value) => {
                                        handleFilterChange(filter.param, value, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(filter.param, value);
                                    }}
                                    value={filterValues[filter.param] || filter.value}
                                />
                            );
                        case 'checkbox':
                            return (
                                <CheckboxFilter
                                    className={filter.className}
                                    key={filter.param}
                                    options={filter.options || []}
                                    onChange={(value) => {
                                        handleFilterChange(filter.param, value, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(filter.param, value);
                                    }}
                                    value={filterValues[filter.param] || filter.value}

                                />
                            );
                        case 'range':
                            return (
                                <RangeFilter
                                    className={filter.className}
                                    key={filter.param}
                                    minPlaceholder={filter.minPlaceholder || ''}
                                    maxPlaceholder={filter.maxPlaceholder || ''}
                                    onMinChange={(value: any) => {
                                        handleFilterChange(`${filter.param}Min`, value, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(`${filter.param}Min`, value);
                                    }}
                                    onMaxChange={(value: any) => {
                                        handleFilterChange(`${filter.param}Max`, value, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(`${filter.param}Max`, value);
                                    }}
                                    minValue={filter.minValue || 0}
                                    maxValue={filter.maxValue || 0}
                                />
                            );
                        case 'text':
                            return (
                                <TextSearchFilter
                                    loadingSuggestion={loadingSuggestion}
                                    setText={setText}
                                    className={filter.className}
                                    key={filter.param}
                                    options={filter.options || []}
                                    placeholder={filter.placeholder || ''}
                                    onChange={(value) => {
                                        handleFilterChange(filter.param, value, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(filter.param, value);
                                    }}

                                    value={filterValues[filter.param] || filter.value || ""}
                                />
                            );
                        case 'checkboxGroup':
                            return (
                                <CheckboxGroupFilter
                                    className={filter.className}
                                    key={filter.param}
                                    sections={filter?.sections || []}
                                    placeholder={filter.placeholder || ''}
                                    multiple={filter.multiple}
                                    onChange={(param, value) => {
                                        handleFilterChange(param, value, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(param, value);
                                    }}
                                    handleApplayFilterChange={handleApplayFilterChange}
                                />
                            );
                        case 'minMax':
                            return (
                                <MinMaxInput
                                    className={filter.className}
                                    key={filter.param}
                                    minPlaceholder={filter.minPlaceholder || ''}
                                    maxPlaceholder={filter.maxPlaceholder || ''}
                                    minValue={filter.minValue || 0}
                                    maxValue={filter.maxValue || 10000}
                                    onMinChange={(value) => handleFilterChange(`${filter.param}Min`, value)}
                                    onMaxChange={(value) => handleFilterChange(`${filter.param}Max`, value)}
                                />
                            );
                        case 'advanced':
                            return (
                                <AdvancedFilter
                                    className={filter.className}
                                    key={filter.param}
                                    sections={filter.sections || []}
                                    placeholder={filter.placeholder || ''}
                                    multiple={filter.multiple}
                                    onChange={(param, value, directQueryOnChange) => {
                                        handleFilterChange(param, value, filter.directQueryOnChange ? directQueryOnChange : false);
                                        if (filter.onChange) filter?.onChange(param, value);
                                    }}
                                    handleApplayFilterChange={handleApplayFilterChange}

                                />
                            );
                        case 'location':
                            return (
                                <LocationSearchFilter
                                    key={filter.param}
                                    value={filter.value}
                                    placeholder={filter.placeholder}
                                    className={filter.className}
                                    onPlaceSelected={(place: PlaceResult) => {
                                        handleFilterChange(filter.param, place, filter.directQueryOnChange);
                                        if (filter.onChange) filter?.onChange(filter.param, place);
                                    }}
                                />
                            );
                        default:
                            return null;
                    }
                })}
                {showSearchButton && (
                    <button className="btn search-btn search" onClick={handleSearch}>
                        <img className="img-icon" src={searchIcon} alt="Save" />

                        <span className="text-icon">{t('filters.Search')}</span>
                    </button>
                )}
                {showSaveButton && (
                    <button className="btn save-btn" onClick={handleSave}>
                        <img className="img-icon" src={saveIcon} alt="Save" />

                        <span className="text-icon">{t('filters.Save')}</span>

                    </button>
                )}
                {showResetButton && (
                    <button className="btn reset-container-btn" onClick={handleReset}>
                        <img className="img-icon" src={resetIcon} alt="Save" />

                        <span className="text-icon">{t('common.reset')}</span>

                    </button>
                )}
                {isUserModalVisible && (
                    <Modal
                        getContainer={false}
                        open={isUserModalVisible}
                        onCancel={showUserModal}
                        footer={null}
                        centered
                        loading={isLoading}
                        confirmLoading
                        modalRender={(modal) => (
                            <div className="modalCard" >
                                {modal}
                            </div>
                        )}
                        style={{ padding: "0px" }}
                    >
                        <Login />
                    </Modal>

                )}


            </div>
        </div>
    );
};

export default FilterContainer;
