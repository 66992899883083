import { useEffect, useRef, useState } from "react";
import logo from '../../assets/logo.svg';
import './navBar.style.scss';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { Card, Divider, Modal, Spin } from 'antd';
import Login from "../../pages/user/login/login";
import { useAuthContext } from "../../auth/AuthProvider";
import Dropdown from "../atoms/Dropdown/Dropdown";
import SettingsModal from "../Modals/SettingsModal/SettingsModal";
import { LoginProvider } from "../../auth/useAuth";
import DeleteAccountModal from "../Modals/DeleteAccountModal/DeleteAccountModal";
import ResetPasswordModal from "../Modals/ResetPasswordModal/ResetPasswordModal";
import EditProfileModal from "../Modals/EditProfileModal/EditProfileModal";
import { User } from "../../auth/authService";
import { useDeleteSaveSearch } from "../../services/userServices";
import DropdownSavedSearch from "../atoms/DropdownSavedSearch/DropdownSavedSearch";

export default function NavBar() {
    const path = useLocation().pathname;
    const location = useLocation()
    const [burgerOpen, setBurgerOpen] = useState(false);
    const { toggleLanguage, buttonText } = useLanguage();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sideMenuRef = useRef<HTMLDivElement>(null);

    const toggleSidebar = () => {
        setBurgerOpen(!burgerOpen);
        document.body.classList.toggle('no-scroll', burgerOpen);
    };


    // User
    const { user, isAuthenticated, isLoading, logout, getAuthToken } = useAuthContext();
    const [authToken, setAuthToken] = useState<string | null>(null);
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);
    const showUserModal = () => setIsUserModalVisible(true);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isResetModalVisible, setIsResetModalVisible] = useState(false);
    const [isEditProfileModalVisible, setIsEditProfileModalVisible] = useState(false);

    if (isAuthenticated && isUserModalVisible) {
        setIsUserModalVisible(false);
    }

    const isPropertyActive = path === '/properties' || path.startsWith('/property');
    const isAgentActive = path === '/agents' || path.startsWith('/agentProfile');
    const isCompanyActive = path === '/companies' || path.startsWith('/companyProfile');
    const isBlogsActive = path === '/blogs' || path.startsWith('/blog');

    const handleAccountDeleted = () => {

        // Perform any additional logic after account deletion (e.g., logout, redirect)
        console.log("Account deleted successfully.");
        logout()
    };

    const handleEditProfileClick = () => {
        setIsEditProfileModalVisible(true); // Open the Edit Profile modal
    };

    const handleCancelEditProfile = () => {
        setIsEditProfileModalVisible(false); // Close the modal
    };

    const handleSaveProfile = (updatedProfile: User) => {
        console.log('Profile updated:', updatedProfile);
        setIsEditProfileModalVisible(false);
    };

    useEffect(() => {
        getAuthToken()
            .then((token) => setAuthToken(token))
            .catch((err) => console.error("Failed to retrieve token:", err));
    }, [user, isAuthenticated]);

    useEffect(() => {
        console.log("NavBar Auth State Changed:", { user, isAuthenticated, isLoading });
        const token = getAuthToken().then((token) => {
            console.log("Token:", { token });
        }).catch((err) => { });
    }, [user, isAuthenticated, isLoading]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (sideMenuRef.current && !sideMenuRef.current.contains(event.target as Node)) {
                setBurgerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    // Settings
    const [activeSetting, setActiveSetting] = useState<string | null>(null);
    const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>("");

    const handleApplySettings = (value: string) => {
        handleCancelSettings();
    }
    const handleCancelSettings = () => {
        setIsUserModalVisible(false);
        setIsSettingModalVisible(false);
        setActiveSetting(null);
    };
    const openSettingModal = (setting: string) => {
        setActiveSetting(setting);
        setIsSettingModalVisible(true);
    };
    const { refetch: DeleteSaveSearch } = useDeleteSaveSearch();
    const handleSelect = (param: any) => {

        const params = new URLSearchParams(param);
        // Decode the specific parameters
        params.set('bedroomNums', decodeURIComponent(param.bedroomNums));
        params.set('bathroomNums', decodeURIComponent(param.bathroomNums));
        params.set('typeIDs', decodeURIComponent(param.typeIDs));

        navigate(`/properties?${params.toString()}`);
    };
    const handleEdit = (id: number) => {
        console.log("Edit Item ID:", id);
    };

    useEffect(() => {
        const lockScroll = () => {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden';
        };

        const unlockScroll = () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        if (isUserModalVisible) {
            lockScroll();
        } else {
            unlockScroll();
        }
        return () => {
            unlockScroll();
        };
    }, [isUserModalVisible]);

    return (
        <div className="navBar">
            <div className="settingbar-container">
                <div className="container-width">
                    <div className="settingbar">
                        <div className="lang-setting">
                            <div className="lang" onClick={toggleLanguage}>
                                <img src={'/images/language.svg'} alt="Language" />
                                <span>{buttonText}</span>
                            </div>
                            <Dropdown
                                title={t("nav.Settings")}
                                icon="/images/setting-svgrepo-com.svg"
                                items={[
                                    { label: t("nav.AreaMeasurement"), onClick: () => openSettingModal("area") },
                                    { label: t("nav.ChangeCurrency"), onClick: () => openSettingModal("currency") },
                                    // { label: t("nav.ChangeMode"), onClick: () => openSettingModal("mode") },
                                    { label: t("nav.SortLists"), onClick: () => openSettingModal("sort") },
                                ]}
                            />
                        </div>
                        <div className="user">
                            {isLoading ? (
                                <Spin size="small" />
                            ) : isAuthenticated ? (
                                <div className="user-logged-in">
                                    <Link to="/favorite" className="nav-link">
                                        <img
                                            src="/images/apts/icons/love-svgrepo-com.svg"
                                            alt="My Favourites"
                                            className="nav-icon"
                                        />
                                        <p>  {t("nav.MyFavourites")}</p>

                                    </Link>
                                    <DropdownSavedSearch
                                        title={t("nav.MySearches")}
                                        icon="/images/apts/icons/save-svgrepo-com.svg"
                                        // data={savedSearch?.result ?? []}
                                        onSelect={handleSelect}
                                    // onEdit={handleEdit}
                                    // onDelete={handleDelete}
                                    />
                                    <Dropdown
                                        title={(`${user?.firstName} ${user?.lastName}`) || "User"}
                                        showDropdownArrow={false}
                                        className="user-dropdown"
                                        items={[
                                            ...(user?.loginProvider === LoginProvider.USERNAME_PASSWORD ? [
                                                { label: t("nav.EditMyProfile"), onClick: handleEditProfileClick, icon: "images/home/edit.svg" },
                                            ] : []),
                                            ...(user?.loginProvider === LoginProvider.USERNAME_PASSWORD ? [
                                                { label: t("nav.ResetPassword"), onClick: () => setIsResetModalVisible(true), icon: "images/home/reset.svg" }
                                            ] : []),
                                            { label: t("nav.DeleteAccount"), onClick: () => setIsDeleteModalVisible(true), icon: "images/home/delete.svg" },
                                            { label: t("nav.Signout"), onClick: logout, icon: "images/home/signout.svg", className: "danger" },
                                        ]}
                                    />
                                </div>
                            ) : (
                                <button className="Login" onClick={showUserModal}>
                                    {t("nav.Login")}
                                </button>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <div className="navBar-container">
                <div className="container-width">
                    <div className={`main ${path === '/' ? 'above-banner' : ''}`}>
                        <div className="logo">
                            <Link to="/"><img src={logo} alt="Logo" width={"147px"} height={"31.26px"} /></Link>
                        </div>
                        <div className="menuLinks">
                            <Link to="properties" className={isPropertyActive ? "active" : ""}>{t("nav.Properties")}</Link>
                            <Link to="agents" className={isAgentActive ? "active" : ""}>{t("nav.Agents")}</Link>
                            <Link to="companies" className={isCompanyActive ? "active" : ""}>{t("nav.Companies")}</Link>
                            <Link to="blogs" className={isBlogsActive ? "active" : ""}>{t("nav.Blogs")}</Link>
                        </div>
                    </div>
                    <div className="burger" >
                        <div>
                            <img src={'/images/menu_svgrepo.com.svg'} alt="Menu" onClick={toggleSidebar} />
                        </div>
                        <div style={{ flex: 1, textAlign: "center" }}>
                            <Link to="/"><img src={logo} alt="Logo" width={"147px"} height={"31.26px"} /></Link>
                        </div>
                        {burgerOpen && (
                            <>
                                <div className="opacity" onClick={toggleSidebar}>
                                </div>
                                <div className="side-menu">
                                    <div className="logo-content">
                                        <Link to="/"><img src={logo} alt="Logo" className="mlogo" width={"147px"} height={"31.26px"} /></Link>
                                    </div>
                                    <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />

                                    <div className="menu">
                                        {/* <img className='close' src={'/images/close-circle_svgrepo.com.svg'} alt="Close" onClick={toggleSidebar} /> */}
                                        {!isAuthenticated && (
                                            <Card className="login card-item" style={{ width: 276 }} onClick={showUserModal}>
                                                Sign In / Sign Up
                                            </Card>
                                        )}

                                        {isAuthenticated && (
                                            <Card className="card-item" style={{ width: 276 }}>
                                                <div className="item-content user" onClick={() => handleEditProfileClick()}>
                                                    <div className={`nav-link`} >
                                                        <div>
                                                            <p className="userName">{`${t("nav.hello")} ${user?.firstName} ${user?.lastName}`} </p>
                                                            <p className="email">{user?.email} </p>
                                                        </div>

                                                        <img
                                                            src="images/home/edit-profile.svg"
                                                            alt="reset"
                                                            className="nav-icon"
                                                        />

                                                    </div>
                                                </div>

                                            </Card>
                                        )}


                                        <Card className="card-item" style={{ width: 276 }}>
                                            <div className="item-content" onClick={toggleSidebar}>
                                                <Link to="properties" className={`nav-link ${path === '/properties' ? "active" : ""}`}>{t("nav.Properties")}</Link>
                                            </div>
                                            <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />
                                            <div className="item-content" onClick={toggleSidebar}>
                                                <Link to="agents" className={`nav-link ${path === '/agents' ? "active" : ""}`}>{t("nav.Agents")}</Link>
                                            </div>
                                            <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />
                                            <div className="item-content" onClick={toggleSidebar}>
                                                <Link to="companies" className={`nav-link ${path === '/companies' ? "active" : ""}`}>{t("nav.Companies")}</Link>
                                            </div>
                                            <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />
                                            <div className="item-content" onClick={toggleSidebar}>
                                                <Link to="blogs" className={`nav-link ${path === '/blogs' ? "active" : ""}`}>{t("nav.Blogs")}</Link>
                                            </div>

                                        </Card>
                                        {isAuthenticated && (
                                            <Card className="card-item" style={{ width: 276 }}>
                                                <div className="item-content" onClick={toggleSidebar}>

                                                    <Link to="/favorite" className={`nav-link ${path === '/favorite' ? "active" : ""}`}>
                                                        <img
                                                            src="/images/apts/icons/love-svgrepo-com.svg"
                                                            alt="My Favourites"
                                                            className="nav-icon"
                                                        />
                                                        <p>  {t("nav.MyFavourites")}</p>

                                                    </Link>
                                                </div>
                                                <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />
                                                <div className="item-content">
                                                    <DropdownSavedSearch
                                                        title={t("nav.MySearches")}
                                                        icon="/images/apts/icons/save-svgrepo-com.svg"
                                                        // data={savedSearch?.result ?? []}
                                                        onSelect={handleSelect}

                                                    />
                                                </div>
                                            </Card>
                                        )}


                                        <Card className="card-item" style={{ width: 276 }}>
                                            {/* <div className="lang" onClick={toggleLanguage}>
                                            <img src={'/images/language.svg'} alt="Language" />
                                            <span>{buttonText}</span>
                                        </div> */}
                                            <div className="item-content" onClick={toggleLanguage}>
                                                <div className={`nav-link`} >
                                                    <img
                                                        src="/images/language.svg"
                                                        alt="language"
                                                        className="nav-icon"
                                                    />
                                                    <p>  {`${t('AgentProfile.Languages')} (${buttonText})`}</p>

                                                </div>
                                            </div>
                                            <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />

                                            <div className="item-content" onClick={() => openSettingModal("area")}>
                                                <div className={`nav-link`} >
                                                    <img
                                                        src="images/home/area.svg"
                                                        alt="reset"
                                                        className="nav-icon"
                                                    />
                                                    <p>  {t("nav.AreaMeasurement")}</p>

                                                </div>
                                            </div>
                                            <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />

                                            <div className="item-content" onClick={() => openSettingModal("currency")}>
                                                <div className={`nav-link`}>
                                                    <img
                                                        src="images/home/currency.svg"
                                                        alt="delete"
                                                        className="nav-icon"
                                                    />
                                                    <p>  {t("nav.ChangeCurrency")}</p>

                                                </div>
                                            </div>


                                        </Card>
                                        {isAuthenticated && (
                                            <Card className="card-item" style={{ width: 276 }}>

                                                <div className="item-content" onClick={() => handleEditProfileClick()}>
                                                    <div className={`nav-link`} >
                                                        <img
                                                            src="images/home/edit.svg"
                                                            alt="reset"
                                                            className="nav-icon"
                                                        />
                                                        <p>  {t("nav.EditMyProfile")}</p>

                                                    </div>
                                                </div>
                                                <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />

                                                <div className="item-content" onClick={() => setIsResetModalVisible(true)}>
                                                    <div className={`nav-link`} >
                                                        <img
                                                            src="images/home/reset.svg"
                                                            alt="reset"
                                                            className="nav-icon"
                                                        />
                                                        <p>  {t("nav.ResetPassword")}</p>

                                                    </div>
                                                </div>
                                                <Divider style={{ margin: 0, padding: 0, borderColor: 'rgba(242, 242, 242, 1)' }} />

                                                <div className="item-content" onClick={() => setIsDeleteModalVisible(true)}>
                                                    <div className={`nav-link`}>
                                                        <img
                                                            src="images/home/delete.svg"
                                                            alt="delete"
                                                            className="nav-icon"
                                                        />
                                                        <p>  {t("nav.DeleteAccount")}</p>

                                                    </div>
                                                </div>

                                                <div className="item-content" onClick={() => logout()}>
                                                    <div className={`nav-link danger`}>
                                                        <img
                                                            src="images/home/signout.svg"
                                                            alt="delete"
                                                            className="nav-icon"
                                                        />
                                                        <p>  {t("nav.Signout")}</p>

                                                    </div>
                                                </div>

                                            </Card>
                                        )}

                                        {/* <div className="links">

                                        <div className="lang" onClick={() => { toggleSidebar(); toggleLanguage(); }}>
                                            <img src={'/images/language.svg'} alt="Language" />
                                            <span>{buttonText}</span>
                                        </div>
                                        <div className="setting">
                                            <img src={'/images/setting-svgrepo-com.svg'} alt="Settings" />
                                            <span>{t("nav.Settings")}</span>
                                        </div>
                                        <Link to="properties" className={path === '/properties' ? "active" : ""} onClick={toggleSidebar}>{t("nav.Properties")}</Link>
                                        <Link to="agents" className={path === '/agents' ? "active" : ""} onClick={toggleSidebar}>{t("nav.Agents")}</Link>
                                        <Link to="companies" className={path === '/companies' ? "active" : ""} onClick={toggleSidebar}>{t("nav.Companies")}</Link>
                                    </div> */}
                                    </div>
                                </div>
                            </>
                        )}
                        {isAuthenticated ? (
                            <div className="user-logged-in">
                                {`${user?.firstName} ${user?.lastName}`}
                                <button onClick={logout}>{t("nav.Logout")}</button>
                            </div>
                        ) : (
                            <button className="Login" onClick={showUserModal}>{t("nav.Login")}</button>
                        )}
                    </div>
                </div>
            </div>
            {isUserModalVisible && (
                <Modal
                    getContainer={false}
                    open={isUserModalVisible}
                    onCancel={handleCancelSettings}
                    footer={null}
                    // loading={isLoading}
                    centered
                    modalRender={(modal) => (
                        <div className="modalCard" >
                            {modal}
                        </div>
                    )}
                    style={{ padding: "0px" }}
                >
                    <Login isLoading={isLoading} />
                </Modal>
            )}


            <SettingsModal
                visible={isSettingModalVisible}
                onCancel={handleCancelSettings}
                onApply={handleApplySettings}
                activeSetting={activeSetting}
                selectedOption={selectedOption}
                onOptionChange={setSelectedOption}
            />

            <DeleteAccountModal
                visible={isDeleteModalVisible}
                onClose={() => setIsDeleteModalVisible(false)}
                onAccountDeleted={handleAccountDeleted}
            />

            <ResetPasswordModal
                visible={isResetModalVisible}
                onClose={() => setIsResetModalVisible(false)}
            />

            <EditProfileModal
                visible={isEditProfileModalVisible}
                user={user}
                token={authToken ?? ""}
                onCancel={handleCancelEditProfile}
                onSave={handleSaveProfile}
            />
        </div>
    );
}
