import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import sortsIcon from '../../../assets/icons/filter/sorts.svg'
import SettingsModal from '../../Modals/SettingsModal/SettingsModal';
import { useSettings } from '../../../context/SettingsContext';
import './Sort.scss';
import { useGetSortLists } from '../../../services/settingsServices';



const Sort: React.FC = () => {
    const { t } = useTranslation();
    const { result: sortResult, loading: sortLoading } = useGetSortLists();

    const [isSettingModalVisible, setIsSettingModalVisible] = useState(false);

    const [selectedOption, setSelectedOption] = useState<string>("");
    const { sort } = useSettings();


    const handleCancelSettings = () => {
        setIsSettingModalVisible(false);
    };
    const openSettingModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsSettingModalVisible(true);
    };
    const handleApplySettings = () => {
        handleCancelSettings();
    }

    const getSelectedLabel = (name: string) => {
        const selected = sortResult.find(option => option.name === name);
        return selected ? t(selected.label) : ""; // Use translation function
    };
    console.log("selectedOption", sort);

    return (
        <div className="sort-container">
            <div className="tooltip-container">
                <button
                    className='sort-btn'
                    onClick={openSettingModal}
                >
                    {t('property.sort')}
                    <img className="img-icon" src={sortsIcon} alt="Sort" />
                </button>
                {selectedOption && (
                    <div className="tooltip">
                        {getSelectedLabel(selectedOption)} {/* Display the translated label */}
                    </div>
                )}
            </div>
            <SettingsModal
                visible={isSettingModalVisible}
                onCancel={handleCancelSettings}
                onApply={handleApplySettings}
                activeSetting={"sort"}
                selectedOption={selectedOption}
                onOptionChange={setSelectedOption}
            />
        </div>
    );
};

export default Sort;
