import { useState, useCallback, useEffect } from "react";
import './companyProfile.scss';
import { useParams } from "react-router-dom";
import Apartments from "../../components/apartments/apartments";
import { useLanguage } from "../../context/LanguageContext";
import { CompanyFilterParam } from "../../helpers/types/filter";
import { useGetCompany, usePostCompanyClick } from "../../services/companiesServices";
import { useTranslation } from "react-i18next";
import { useGetProperties } from "../../services/propertiesServices";
import ErrorComponent from "../../components/errorComponent/error";
import { PuffLoader } from "react-spinners";
import call from '../../assets/icons/call.svg';
import email from '../../assets/icons/email.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';
import share from '../../assets/icons/share.svg';
import infoIcon from '../../assets/icons/info.svg';
import Tabs from "../../components/tabs/tabs";
import { useGetAgents } from "../../services/agentsServices";
import AgentCard from "../../components/agentCard/agentCard";
import { convertObjectToImageString, whatsAppME } from "../../helpers/helpers";
import { ClickAction } from "../../helpers/types/general";
import ShareModal from "../../components/Modals/ShareModal/ShareModal";
import { useNativeShare } from "../../components/Modals/ShareModal/nativeShare";
import EmailModal from "../../components/emailPopup/emailModal";
import { Grid, Spin } from "antd";

export default function CompanyProfile() {
    const id = useParams().id;
    const screens = Grid.useBreakpoint();
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);

    const [formClickRequest, setFormClickRequest] = useState<ClickAction>({
        Id: Number(id),
        click: "",
        sourceType: ''
    });
    const { responseData: postClickResponse, refetch: refetchClick } = usePostCompanyClick(formClickRequest);
    const { company: companyInfo, companyLoading, companyError, refetch } = useGetCompany(Number(id), language.toUpperCase())
    const company = companyInfo?.result[0];
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
            setIsMobile(true);
        }
    }, []);
    const handleNativeShare = useNativeShare(company?.shareMsg);

    const [currentPage, setCurrentPage] = useState(1);
    const [filterParams, setFilterParams] = useState<CompanyFilterParam>({
        companyIDs: [Number(id)],
        orderByMode: 'LTH',
        page: currentPage - 1,
        size: 10,
        language: language.toUpperCase(),
        channel: 'W',
    });

    const { properties: data, loading, error } = useGetProperties(filterParams);
    const [properties, setProperties] = useState(data?.result || []);
    const totalItems = data?.pageInfo?.totalElements || 0;

    const { result: resAgents, loading: loadingAgents } = useGetAgents(filterParams);
    const agents = resAgents?.result;
    const totalItemsAgents = resAgents?.pageInfo?.totalElements || 0;

    const isHasNextPage = data?.pageInfo?.hasNext
    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    }
    useEffect(() => {
        setFilterParams((prevParams) => ({
            ...prevParams,
            page: currentPage - 1,
        }));
    }, [currentPage]);
    useEffect(() => {
        setFilterParams((prevParams) => ({
            ...prevParams,
            page: currentPage - 1,
        }));
    }, [currentPage]);

    useEffect(() => {
        if (data?.result) {
            setProperties((prevProperties: any) => [...prevProperties, ...data.result]);
        }
    }, [data]);
    const tabs = [
        {
            label: t(`common.Properties`),
            countLabel: t(`common.properties`),
            count: totalItems,
            content: (
                <div className="properties-list">
                    {loading && properties.length === 0 &&
                        <div className="loader" >
                            <PuffLoader color="#54B0ED" loading={loading} />
                        </div>
                    }
                    {error && <ErrorComponent message={t('AgentProfile.ErrorLoadingProperties')} />}
                    { properties?.length > 0 && (
                        <>
                          <div className="props">
                            <Apartments apartments={properties} />
                          
                        </div>
                        {isHasNextPage && (
                                        loading ? (
                                            <Spin
                                                style={{
                                                    margin: '0 auto',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    padding: screens.xs ? '30px 0px 30px 0px' : '80px 0px 77px 0px',
                                                }}
                                            />
                                        ) :
                                            (
                                                <button
                                                    className="seeMoreBtn"
                                                    onClick={handleLoadMore}

                                                >
                                                    {t('BlogsAndNews.SeeMore')}
                                                </button>
                                            )

                                    )}
                        </>
                      
                    )}
                    {!loading && properties?.length === 0 && (
                        <div className="no-properties">{t('AgentProfile.NoPropertiesFound')}</div>
                    )}
                </div>
            )
        },
        {
            label: t(`common.Agents`),
            countLabel: t(`common.agents`),
            count: totalItemsAgents,
            content: (
                <div className="agents-list">
                    {loadingAgents &&
                        <div className="loader" >
                            <PuffLoader color="#54B0ED" loading={loadingAgents} />
                        </div>
                    }
                    {!loadingAgents && agents?.length && (
                        <div className="agents-grid">
                            {agents?.map((agent: any, index: any) => (
                                <div className="item" key={index}>
                                    <AgentCard agent={agent} />
                                </div>
                            ))}
                        </div>
                    )}
                    {!loadingAgents && agents?.length === 0 && (
                        <div className="no-agents">{t('CompanyProfile.NoAgentsFound')}</div>
                    )}
                </div>
            )
        }
    ];
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
        setFormClickRequest({
            Id: Number(id),
            click: 'E',
            sourceType: 'C'
        });
    };
    // Use useCallback to optimize the share button click handler
    const handleShareClick = useCallback(() => {
        if (isMobile) {
            handleNativeShare()
        }
        else {
            setIsShareModalVisible(true);
        }
    }, []);

    const handleCloseShareModal = () => {
        setIsShareModalVisible(false);
    };

    const handleCallClick = () => {
        window.location.href = `tel:${company?.phone}`;
        setFormClickRequest({
            Id: Number(id),
            click: 'M',
            sourceType: 'C'
        });
    };
    const handleWhatsAppClick = () => {
        whatsAppME(company?.whatsappNumber || "", company?.whatsAppMsg ?? "");
        setFormClickRequest({
            Id: Number(id),
            click: 'W',
            sourceType: 'C'
        });
    };
    useEffect(() => {
        if (formClickRequest.click && formClickRequest.sourceType) {
            refetchClick();
        }
    }, [formClickRequest]);

    return (
        <div className={`company-profile`}>
            <div className="company-header">
                <picture>
                    <source
                        media="(max-width: 768px)"
                        srcSet="https://housez.s3.ap-south-1.amazonaws.com/Website/Company/Company+Profile+Background-+Mobile+view.jpeg"
                    />
                    <img
                        src="https://housez.s3.ap-south-1.amazonaws.com/Website/Company/Company+Profile+Background-+Mobile+view.jpeg"
                        alt="Company Background"
                        className="header-background"
                    />
                </picture>
                <div className="company-details container">
                    <div className="personal-hide"></div>
                    <div className="personal">
                        <div className="company-logo-container">
                            {company?.logo ? (
                                <img className="company-logo" src={convertObjectToImageString(company.logo)} alt="Company Logo" />
                            ) : (
                                <div className="company-logo-placeholder">
                                    <span>{company?.name?.charAt(0).toUpperCase() ?? "R"}</span>
                                </div>
                            )}
                        </div>
                        <h1>{company?.name ?? ""}</h1>
                        <p>{company?.emirate ?? ""}</p>
                    </div>

                    <div className="contact">
                        <a className="contact-item" onClick={handleCallClick}>
                            <img src={call} alt="" />
                            <span>{t('common.call')}</span>
                        </a>
                        <a className="contact-item" onClick={showModal}>
                            <img src={email} alt="" />
                            <span>{t('common.email')}</span>
                        </a>
                        <div className="contact-item" onClick={handleWhatsAppClick}>
                            <img src={whatsapp} alt="" />
                            <span>{t('common.whatsApp')}</span>
                        </div>
                        <div className="contact-item" onClick={handleShareClick}>
                            <img src={share} alt="" />
                            <span>{t('common.shareProfile')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="company-content container">
                <div className="left-content">
                    <div className="info-section">
                        <p className="description-section">
                            <p className="description-text">{t('CompanyProfile.Description')}</p>
                            <span>{company?.description}</span>
                        </p>
                        <p className="address-section">
                            {t('CompanyProfile.address')} <span>{company?.address ?? ""}</span>
                        </p>
                        <p className="year-of-establishment-section">
                            {t('CompanyProfile.year-of-establishment')} <span>{company?.yearOfEstablishment ?? ""}</span>
                        </p>
                        <p className="type-section">
                            {t('CompanyProfile.type')} <span>{company?.type ?? ""}</span>
                        </p>
                        <p className="orn-section">
                            {t('CompanyProfile.ORN')}
                            <span className="info-container" title="Official Reference Number (ORN)">
                                <img src={infoIcon} alt="info" className="info-icon" />
                            </span>
                            <span>{company?.ORN ?? ""}</span>
                        </p>
                        <p className="rera-section">
                            {t('CompanyProfile.RERA')}
                            <span className="info-container" title="Real Estate Regulatory Authority (RERA)">
                                <img src={infoIcon} alt="info" className="info-icon" />
                            </span>
                            <span>{company?.rera ?? ""}</span>
                        </p>
                    </div>
                </div>

                <div className="right-content">
                    <Tabs tabs={tabs} />
                </div>
            </div>

            <ShareModal isOpen={isShareModalVisible} onClose={handleCloseShareModal} message={company?.shareMsg} />
            <EmailModal msg={company?.companyEmailMsg} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />

        </div >
    );
}
