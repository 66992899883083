import React from 'react';
import './layoutImageArrows.scss';

interface ArrowProps {
    direction: 'prev' | 'next';
    onClick: () => void;
    className: string;
}

const CustomLayoutArrow: React.FC<ArrowProps> = ({ direction, onClick, className }) => {
    return (
        <button
            className={`custom-layout-arrow ${direction} ${className}`}
            onClick={onClick}
        >
            {direction === 'prev' ? <img src='/images/apts/icons/leftLayoutArrow.svg' /> :
                <img src='/images/apts/icons/rightLayoutArrow.svg' />}
        </button>
    );
};

export default CustomLayoutArrow;
