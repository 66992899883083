import React, { useEffect, useState } from "react";
import './RnowPayLatet.scss';
import { useTranslation } from "react-i18next";
import { Button, Col, Grid, Input, Row } from "antd";

interface RentOrSellType {
    rentSellType: any;
}

export default function RnowPayLater({ rentSellType }: RentOrSellType) {
    const { t } = useTranslation();
    const screens = Grid.useBreakpoint()
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (num: any) => {
        setActiveButton(num);
    };
    const [checkWidth, setCheckWidth] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1200) {
                setCheckWidth(true);
            } else {
                setCheckWidth(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    console.log(checkWidth, "wth")
    return (
        <div className="RnowPlater">
            {rentSellType == 'Sell' ? (
                <>
                    <span className="RnowPlater-title">
                        {t("Get the Best Mortgage Rate")}
                    </span>
                    <Row gutter={[20, 0]}>
                        <Col xs={24} sm={24} md={24} lg={checkWidth ? 24 : 13}>
                            <div style={{ width: "100%", height: "auto" }}>
                                <iframe
                                    src="https://housez.useholo.com/en/wlp-mortgage-calculator"
                                    title="Mortgage Calculator"
                                    className="RnowPlater-iframe"
                                    frameBorder="0"
                                    scrolling="no"
                                    style={{
                                        width: '100%',
                                        height: '800px',
                                        minHeight: '800px',
                                    }}
                                ></iframe>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={checkWidth ? 0 : 11} className="hideSection">
                            <div className="mortgageDesc">
                                <p className="uaeFirst">UAE’s First</p>
                                <p className="digitalMortgage">
                                    Digital Mortgage Calculator
                                </p>
                                <p className="getTheBest">
                                    Get the Best Rates with Expert Guidance!
                                </p>
                                <p className="pweredby">
                                    Powered by <img src="/images/apts/icons/holoIcon.svg" alt="holo logo" />
                                </p>
                            </div>
                        </Col>
                    </Row>


                </>
            ) : (
                <>
                    <span className="RnowPlater-title">
                        {t("Rent Now, Pay Later")}
                    </span>
                    <div className="RnowPlater-content">
                        <h2>{t("Pay Your Annual Rent in Installments")}</h2>
                        <form>
                            <Row gutter={[20, 0]}>

                                <Col xs={24} sm={12} md={24} lg={12}>
                                    <div className="rent-section">
                                        <label htmlFor="yearlyRent">{t("Your yearly rent")}</label>
                                        <Input
                                            type="text"
                                            id="yearlyRent"
                                            defaultValue="AED 50,000"
                                            className="ant-input"
                                        />
                                    </div>
                                </Col>

                                <Col xs={24} sm={12} md={24} lg={12}>
                                    <div className="installment-section"
                                        style={{
                                            alignItems: screens.lg ? 'center' : 'start'
                                        }}
                                    >
                                        <h4>{t("How many installments you pay")}</h4>
                                        <div className="installment-buttons">
                                            {[2, 4, 6, 8, 10, 12].map(num => (
                                                <Button
                                                    key={num}
                                                    type="default"
                                                    className={activeButton === num ? "active" : ""}
                                                    onClick={() => handleButtonClick(num)}
                                                >
                                                    {num}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                </Col>

                            </Row>

                            <Row gutter={[20, 0]} className="section2">

                                <Col xs={24} sm={12} md={24} lg={12}>
                                    <div className="monthly-rent">
                                        <p>{t("Your new monthly rent with Keyper")}</p>
                                        <h3>{t("AED 4,200")}</h3>
                                    </div>
                                </Col>

                                <Col xs={24} sm={12} md={24} lg={10}>
                                    <div className="startedBtn"
                                        style={{
                                            alignItems: screens.lg ? 'end' : 'center'
                                        }}
                                    >
                                        <div className="">
                                            <Button type="primary" className="cta-button">
                                                {t("Let's get started")}
                                            </Button>
                                            <p className="powered-by">
                                                {t("Powered by ")}
                                                <img src="/images/apts/icons/kyper.svg" alt="Keyper logo" />
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
}
