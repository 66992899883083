import "./agents.scss";
import { useEffect, useState } from "react";
import AgentCard from "../../components/agentCard/agentCard";
import { useLanguage } from "../../context/LanguageContext";
import { PuffLoader } from "react-spinners";
import {
  useFetchAgentSuggestions,
  useGetAgents,
} from "../../services/agentsServices";
import PaginationComponent from "../../components/Pagination/PaginationComponent";
import { AgentFilterParam } from "../../helpers/types/filter";
import FilterContainer from "../../components/filters/FilterContainer";
import {
  useGetAgentsLangeuages,
  useGetAreasByEmirates,
  useGetEmirates,
} from "../../services/filtersServices";
import FilterCountDisplay from "../../components/filters/FilterCountDisplay/FilterCountDisplay";
import { useTranslation } from "react-i18next";
import { useFilterState } from "./filterState";
import { useLocation } from "react-router-dom";
import { Buy_B, Rent_R } from "../../components/RentOrSellConstant/constants";

export default function Agents() {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const location = useLocation();

  // Function to get query parameters from the URL
  const getQueryParams = () => {
    const queryString = location.search; // Get the query string
    const urlParams = new URLSearchParams(queryString); // Create a URLSearchParams object
    return Object.fromEntries(urlParams.entries()); // Convert to an object
  };

  const queryParams = getQueryParams(); // Get the query parameters
  const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 8;
  const initialFilterParams: AgentFilterParam = {
    dealType: Rent_R,
    page: 0,
    size: itemsPerPage,
    language: language.toUpperCase(),
    channel: "W",
    agentIDs: queryParams && queryParams?.agentIDs ? [Number(queryParams?.agentIDs)] : undefined
  };
  const [filterParams, updateFilterParams] =
    useFilterState(initialFilterParams);

  // Function to reset filter parameters
  const resetFilterParams = () => {
    setTextSearchAgentName("")
    updateFilterParams({
      ...initialFilterParams,

      agentIDs: undefined,
      areaIDs: [],
      langIDs: [],
      emirateIDs: [],

    });

  };


  const { emirates } = useGetEmirates({ language: language.toUpperCase() });
  const [selectedEmirates, setSelectedEmirates] = useState<any>(
    filterParams.emirateIDs || []
  );
  const { areas } = useGetAreasByEmirates(
    selectedEmirates,
    language.toUpperCase()
  );
  const { languages: agentLang } = useGetAgentsLangeuages({
    language: language.toUpperCase(),
  });
  const [textSearchAgentName, setTextSearchAgentName] = useState<string>("");
  const { agents: agentsName } = useFetchAgentSuggestions(textSearchAgentName, Number(queryParams?.agentIDs));
  const { result, loading } = useGetAgents(filterParams);
  const agents = result?.result;
  const totalItems = result?.pageInfo?.totalElements || 0;
  const handlePageChange = (page: number) => {
    updateFilterParams({ page: page - 1 });
  };
  useEffect(() => {
    setSelectedEmirates(filterParams.emirateIDs || []);
  }, [filterParams.emirateIDs]);


  const filtersConfig = [
    {
      type: "select" as const,
      param: "dealType",
      options: [
        { id: Rent_R, name: t("filters.Rent") },
        { id: Buy_B, name: t("filters.Sell") },
      ],
      placeholder: t("filters.RentOrSell_Purpose"),
      value: filterParams.dealType,
      multiple: false,
      className: "rent custom-select",

    },
    {
      type: "text" as const,
      param: "agentIDs",
      placeholder: t("agents.AgentName"),
      value: queryParams?.agentIDs || filterParams.agentIDs,
      options: agentsName,
      className: "agents-select",

    },
    {
      type: "select" as const,
      param: "emirateIDs",
      options: emirates?.result || [],
      placeholder: t("filters.Emirate"),
      value: filterParams.emirateIDs,
      multiple: true,
      onChange: (key: any, value: any) => setSelectedEmirates(value),
      className: "custom-select",
    },
    {
      type: "select" as const,
      param: "areaIDs",
      options: areas?.result || [],
      placeholder: t("filters.Area"),
      value: filterParams.areaIDs,
      multiple: true,
      className: "custom-select",
    },
    {
      type: "select" as const,
      param: "langIDs",
      options: agentLang?.result || [],
      placeholder: t("agents.Language"),
      value: filterParams.langIDs,
      multiple: true,
      className: "custom-select",
    },
  ];

  return (
    <>
      <FilterContainer
        filtersConfig={filtersConfig}
        onFilterChange={updateFilterParams}
        onSearch={updateFilterParams}
        setText={setTextSearchAgentName}

        onReset={() => {
          resetFilterParams()
        }}
      />
      <div className="agents">
        {loading ? (
          <div className="loader">
            <PuffLoader color="#54B0ED" loading={loading} />
          </div>
        ) : (
          <>
            <FilterCountDisplay
              title={t("agents.RealEstateAgentsInUAE")}
              entityName={t("agents.Agents")}
              currentPage={filterParams.page}
              itemsPerPage={filterParams.size}
              totalItems={totalItems}
            />
            <div className="agents-grid">
              {agents?.map((agent: any, index: any) => (
                <div className="item" key={index}>
                  <AgentCard agent={agent} />
                </div>
              ))}
            </div>
            <PaginationComponent
              current={filterParams.page}
              total={totalItems}
              pageSize={filterParams.size}
              onChange={handlePageChange}
            />
          </>
        )}
      </div>
    </>
  );
}
