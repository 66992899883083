import { formatDistanceToNowStrict } from "date-fns";
import { BaseParam, Image, ImageSize } from "./types/general";
import { useLocation } from "react-router-dom";

export const createQueryParams = (filters: BaseParam): URLSearchParams => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value) && value.length > 0) {
      params.append(key, value.join(','));
    } else if (!Array.isArray(value) && value != null && value != undefined && !Number.isNaN(value) && value !== '') {
      params.append(key, value);
    }
  }

  return params;
};
export const whatsAppME = (agentMobile: number | string, message: any, id?: any, location?: any) => {
  const currentUrl = window.location.href;
  let updatedUrl = currentUrl;
  let updatedMsg=message;

  const currentLocation = location || window.location;

  if (currentLocation.pathname === '/') {
    updatedUrl = `${currentUrl}property`;
  } else if (sessionStorage.getItem('aiModal') === "true") {
    updatedUrl = `${window.location.origin}/property`;
  } else if (updatedUrl.includes("properties")) {
    updatedUrl = `${window.location.origin}/property`;
  } else if (updatedUrl.includes("blogs")) {
    updatedUrl = updatedUrl.replace("blogs", "blog");
  }
  if (message?.includes("{1}")) {
    updatedMsg = message?.replace("{1}", "")
  }

  const messageWithUrl = `${updatedMsg} ${updatedUrl}/${id ? id : ''}`;
  
  const url = `https://wa.me/${agentMobile}?text=${encodeURIComponent(messageWithUrl)}`;
  window.open(url, "_blank");
};




export const formatTimeAgo = (date: string | Date): string => {
  if (!date) return '';
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  if (parsedDate.toString() == 'Invalid Date') return date.toLocaleString();
  return formatDistanceToNowStrict(parsedDate) + ' ago';
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
export const splitString = (str: string) => String(str).split('-').join(' ');


export const convertObjectToImageString = (item: Image | undefined, size: ImageSize = ImageSize.Medium): string => {
  if (!item) return "";
  const extension = item?.extension?.startsWith('.') ? item?.extension : `.${item?.extension}`;
  if (!item?.url?.endsWith('/')) return item?.url + extension;

  const name = item?.name ? item?.name : size;
  const url = item?.url;
  const img = url + name + extension;

  return img;
}

// Debounce function to limit API requests when typing
export const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};