import React, { useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import './infoSectionComponent.scss';

interface LinksComponentProps {
    link?: string;
}

const LinksComponent: React.FC<LinksComponentProps> = ({ link }) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    const getYouTubeVideoId = (url: string) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    const handleModalOpen = () => {
        if (link) {
            const videoId = getYouTubeVideoId(link);
            if (videoId) {
                setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
                setIsModalVisible(true);
            } else {
                console.error("Invalid YouTube URL");
            }
        }
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setVideoUrl(null);
    };

    const videoId = link ? getYouTubeVideoId(link) : null;

    return (
        <>
            <span className="video-title">{t('property.Video')}</span>
            <div onClick={handleModalOpen} style={{ cursor: "pointer", position: "relative" }}>
                {videoId && (
                    <img
                        src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                        alt="YouTube Video Thumbnail"
                        style={{ width: "100%", maxWidth: "867px", height: 226, borderRadius: 18, marginBottom: "40px" }}
                    />
                )}
                <img
                    src="/images/apts/icons/youtubeModalSvg.svg"
                    alt="YouTube Logo"
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        pointerEvents: "none",
                        width: "64px",
                        height: "45px"
                    }}
                />
            </div>

            <Modal
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={800}
                className="YouTubeModal"
                closeIcon={false}
                centered
                getContainer={false}
                destroyOnClose={true}
            >
                {videoUrl && (
                    <iframe
                        width="100%"
                        height="400"
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                    ></iframe>
                )}
            </Modal>
        </>
    );
};

export default LinksComponent;