import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Button, Select } from 'antd';
import './styles/CheckboxGroupFilter.scss';
import { ReactComponent as SuffixIcon } from '../../../assets/icons/arrow-filter.svg';
import { useTranslation } from 'react-i18next';

interface SelectOption {
    id: number | string;
    name: string;
}

interface Section {
    label: string;
    param: string;
    value?: any;
    showLabel?: boolean;
    options?: SelectOption[];
}

interface CheckboxGroupFilterProps {
    className?: string;
    sections?: Section[];
    placeholder: string;
    multiple?: boolean;
    witWwhitebackground?: boolean;

    onChange: (param: string, selectedOptions: any[]) => void;
    handleApplayFilterChange?: () => void
}

const CheckboxGroupFilter: React.FC<CheckboxGroupFilterProps> = ({ sections, placeholder, multiple = false, witWwhitebackground = true, onChange, className, handleApplayFilterChange }) => {
    const { t } = useTranslation();
    const [currentPlaceholder, setCurrentPlaceholder] = useState<string>(placeholder);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const initializeSelectedValues = () => {
        const initialValues: Record<string, any[]> = {};
        sections?.forEach((section) => {
            initialValues[section.param] = section.value || selectedValues?.[section.param];
        });
        return initialValues;
    };

    const [selectedValues, setSelectedValues] = useState<Record<string, string[]>>({});
    const [selectedValuesFormatted, setSelectedValuesFormatted] = useState<string | undefined>(undefined);

    useEffect(() => {
        setCurrentPlaceholder(placeholder);
    }, [placeholder]);

    useEffect(() => {
        const updateSelectedValues = initializeSelectedValues();
        setSelectedValues(updateSelectedValues);
    }, [sections]);

    // Handle changes for each section and update state
    const handleCheckboxChange = (sectionParam: string, checkedValues: string[]) => {
        setSelectedValues(prev => ({
            ...prev,
            [sectionParam]: checkedValues,
        }));
        onChange(sectionParam, checkedValues);
    };

    // Reset all selected values
    const handleReset = () => {
        const resetValues: Record<string, string[]> = {};
        sections?.forEach(section => {
            resetValues[section.param] = [];
        });
        setSelectedValues(resetValues); // Reset local state
        // Trigger onChange with empty values
        sections?.forEach(section => onChange(section.param, []));
        setCurrentPlaceholder(placeholder);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    // Update the formatted value whenever selectedValues state changes
    useEffect(() => {
        const formatSelectedValues = () => {
            if (!sections || sections.length === 0) {
                setSelectedValuesFormatted(undefined);
                return;
            }

            const selectedSections = sections
                .map(section => {
                    const _selectedValues = selectedValues[section.param] || [];
                    if (_selectedValues.length > 0) {
                        const selectedNames = _selectedValues
                            .map(selectedId => {
                                const option = section?.options?.find(option => option.id === selectedId);
                                return option ? option.name : '';
                            })
                            .filter(Boolean); // Filter out empty or undefined names

                        return `${selectedNames.join(', ')} ${section.showLabel ? section.label : ''}`;
                    }
                    return '';
                })
                .filter(Boolean)
                .join(' / ');

            if (!selectedSections) {
                setSelectedValuesFormatted(undefined);
            } else {
                setSelectedValuesFormatted(selectedSections);
            }
        };

        formatSelectedValues();
    }, [selectedValues, sections, placeholder]);

    return (
        <div className={`group-filter-container ${className} ${isDropdownOpen ? 'dropdown-open' : ''}`} ref={dropdownRef}>
            <Select
                mode={multiple ? 'multiple' : undefined}
                placeholder={currentPlaceholder}
                onClick={toggleDropdown}
                open={false}
                value={selectedValuesFormatted}
                maxTagCount={2}

                suffixIcon={<SuffixIcon className="custom-arrow-icon" />}
                filterOption={false}
                removeIcon={false}

            />

            {isDropdownOpen && (
                <div className="dropdown-overlay ">
                    <div className={`dropdown-content ${witWwhitebackground ? " white-background" : ""}`}>
                        {sections && sections.map((section, index) => (
                            <div key={index} className="filter-section">
                                {section.showLabel && <h3>{section.label}</h3>}
                                <Checkbox.Group
                                    value={selectedValues[section.param]}
                                    onChange={(checkedValues) => {
                                        handleCheckboxChange(section.param, checkedValues)
                                    }}

                                >
                                    <div className="filter-options">
                                        {section?.options?.map((option) => (
                                            <Checkbox key={option.id} value={option.id} className="checkbox-option circular-checkbox">
                                                {option.name}
                                            </Checkbox>
                                        ))}
                                    </div>
                                </Checkbox.Group>
                            </div>
                        ))}
                        <div className="filter-buttons">
                            <Button className="reset-btn" onClick={handleReset}>
                                {t('common.reset')}
                            </Button>
                            <Button type="primary" className="apply-btn" onClick={() => {
                                handleApplayFilterChange && handleApplayFilterChange()
                                toggleDropdown()
                            }}>
                                {t('common.apply')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CheckboxGroupFilter;
