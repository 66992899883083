import { useEffect, useState } from "react";
import './homeFilter.scss';
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";
import { useGetAreasByEmirates, useGetEmirates, useGetPropTypesByUsage } from "../../services/filtersServices";
import jsonData from '../../Data/data.json';
import LocationSearchFilter, { PlaceResult } from "../filters/FilterTypes/LocationSearchFilter";
import CheckboxGroupFilter from "../filters/FilterTypes/CheckboxGroupFilter";
import AdvancedFilter from "../filters/FilterTypes/AdvancedFilter";
import { createQueryParams } from "../../helpers/helpers";
import { PropertyFilterParam } from "../../helpers/types/filter";
import { ReactComponent as SuffixIcon } from '../../assets/icons/arrow-filter.svg';
import { useSettings } from "../../context/SettingsContext";
import { Buy_B, Rent_R } from "../RentOrSellConstant/constants";


export default function HomeFilter() {
    const [activeTab, setActiveTab] = useState<'R' | 'B' | 'C'>(Rent_R);
    const { t } = useTranslation();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 8;
    const { area } = useSettings();

    const usageType = activeTab === 'C' ? 2 : 1;
    const { propTypes: residentialPropTypes } = useGetPropTypesByUsage(usageType, { language });
    const [selectedPropTypes, setSelectedPropTypes] = useState<number[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<PlaceResult | null>(null);
    const [minSize, setMinSize] = useState<number | null>(null);
    const [maxSize, setMaxSize] = useState<number | null>(null);
    const [homeSelectedBeds, setHomeSelectedBeds] = useState<number[] | undefined>([]);
    const [homeSelectedBaths, setHomeSelectedBaths] = useState<number[] | undefined>([]);

    const beds = jsonData?.beds?.map((bed: any) => {
        if (bed.name === "Studio") {
            return { ...bed, name: t('filters.Studio') };
        }
        return bed;
    });
    const baths = jsonData?.baths;

    const combinedPropTypes = [...(residentialPropTypes?.result || [])];

    const handlePropTypesChange = (value: number[]) => setSelectedPropTypes(value);

    const handleSizeChange = (param: string, value: any) => {

        if (param === 'minSize') {
            setMinSize(value);
        } else if (param === 'maxSize') {
            setMaxSize(value);
        }
    };

    const handleBedsBathsChange = (param: string, value: number[]) => {
        if (param === 'bedroomNums') {
            setHomeSelectedBeds(value);
        } else if (param === 'bathroomNums') {
            setHomeSelectedBaths(value);
        }
    };

    const handleSearch = () => {
        const filters: PropertyFilterParam = {
            typeIDs: selectedPropTypes,
            usageIDs: activeTab === 'C' ? [2] : [1],
            rentOrSell: activeTab === 'C' ? 'CR' : activeTab,
            bedroomNums: activeTab !== 'C' && homeSelectedBeds && homeSelectedBeds?.length > 0 ? homeSelectedBeds : undefined,
            bathroomNums: activeTab !== 'C' && homeSelectedBaths && homeSelectedBaths?.length > 0 ? homeSelectedBaths : undefined,
            minSize: activeTab === 'C' && minSize ? minSize : undefined,
            maxSize: activeTab === 'C' && maxSize ? maxSize : undefined,
            placeId: selectedPlace?.placeId,
            page: 0,
            size: itemsPerPage,
        };

        const params = createQueryParams(filters);

        navigate(`/properties?${params.toString()}`);
    };

    const handlePlaceSelected = (place: PlaceResult) => {
        // Store the place details for search params
        setSelectedPlace({
            ...place,
            placeId: place.placeId,
        });
    };

    useEffect(() => {
        const handleButtonClick = () => {
            setMinSize(null);
            setMaxSize(null);
        };

        // Add event listener to the document
        document.addEventListener("advancedResetButtonClick", handleButtonClick);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener("advancedResetButtonClick", handleButtonClick);
        };
    }, []);
    return (
        <div className="home-filter">
            <div className="btns">
                <div onClick={() => setActiveTab(Rent_R)} className={`btn ${activeTab === Rent_R ? 'active' : ''}`}>
                    {t("filters.Rent")}
                </div>
                <div onClick={() => setActiveTab(Buy_B)} className={`btn ${activeTab === Buy_B ? 'active' : ''}`}>
                    {t("filters.Buy")}
                </div>
                <div onClick={() => setActiveTab('C')} className={`btn ${activeTab === 'C' ? 'active' : ''}`}>
                    {t("filters.Commercial")}
                </div>
            </div>

            <div className="filters">
                <div className={`selects ${language === "ar" ? "ar" : "en"}`}>
                    <div className="item">
                        <LocationSearchFilter
                            placeholder={t('filters.Location')}
                            onPlaceSelected={(place: PlaceResult) => handlePlaceSelected(place)}
                            className="home-location-filter"
                            withWhiteBackground={false}
                        />
                    </div>
                    <div className="item mobile-hide">
                        {/* <Select
                            placeholder={t("filters.PropType")}
                            mode='multiple'
                            maxTagCount='responsive'
                            options={combinedPropTypes?.map((item: any) => ({
                                value: item.id,
                                label: item.name
                            }))}
                            onChange={handlePropTypesChange}
                            className="with-margin"
                            suffixIcon={<SuffixIcon className="custom-arrow-icon" />}
                        /> */}

                        <CheckboxGroupFilter
                            className={"custom-select hide-search property-filter-background property-filter-type"}
                            witWwhitebackground={false}
                            sections={[
                                {
                                    label: 'Types', param: 'typeIDs', options: residentialPropTypes?.result || [], showLabel: false,
                                },
                            ]}
                            placeholder={t('filters.Type')}
                            multiple={true}
                            onChange={(param, value) => handlePropTypesChange(value)} // Handle changes for beds/baths
                        />
                    </div>

                    {/* Render Beds & Baths for Rent/Buy */}
                    {[Rent_R, Buy_B].includes(activeTab) && (
                        <div className="item mobile-hide">
                            <CheckboxGroupFilter
                                className={"custom-select horizontal-select hide-search"}
                                witWwhitebackground={false}
                                sections={[
                                    { label: t('filters.Beds'), param: 'bedroomNums', options: beds, showLabel: true },
                                    { label: t('filters.Baths'), param: 'bathroomNums', options: baths, showLabel: true },
                                ]}
                                placeholder={t('filters.BedsAndBaths')}
                                multiple={true}
                                onChange={(param, value) => handleBedsBathsChange(param, value)} // Handle changes for beds/baths
                            />
                        </div>
                    )}

                    {/* Render Area and Size for Commercial */}
                    {activeTab === 'C' && (
                        <div className="item mobile-hide">
                            <AdvancedFilter
                                className="custom-home-page-filter"
                                witWwhitebackground={false}
                                sections={[{
                                    label: `${t('filters.totalArea')} (${area || t('common.sqft')})`,
                                    type: "minMax",
                                    param: 'minSize',
                                    showLabel: true,
                                    attributes: {
                                        maxParam: "maxSize",
                                        minParam: "minSize",
                                        defaultMaxValue: 10000,
                                        maxValue: maxSize || 10000,
                                        defaultMinValue: 0,

                                        minValue: minSize || 0,
                                        maxPlaceholder: `Maximum ${area}`,
                                        minPlaceholder: `Minimum ${area}`
                                    },

                                }]}
                                placeholder={`${t("filters.Area")} (${area || t('common.sqft')})`}
                                multiple={false}

                                onChange={(param, value) => handleSizeChange(param, value)} // Handle size changes
                                suffixIcon={<SuffixIcon className="custom-arrow-icon" />}
                            // handleApplayFilterChange={handleApplayFilterChange}
                            />
                        </div>
                    )}
                </div>

                <button className="find-btn" onClick={handleSearch}>
                    <img src={'/images/search.svg'} alt="Search" />
                </button>
            </div>
        </div>
    );
}
