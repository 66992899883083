import { createQueryParams } from "../helpers/helpers";
import { BASE_URL } from "../helpers/types";
import useAxios from "axios-hooks";
import { CompanyFilterParam } from "../helpers/types/filter";
import { CompaniesResponse } from "../helpers/types/company";
import { CreateAxiosGetRequest, CreateAxiosPostRequest } from "./GeneralService";
import { ClickAction } from "../helpers/types/general";
import { useLanguage } from "../context/LanguageContext";

export const useGetCompanies = (filters: CompanyFilterParam) => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest<CompaniesResponse>({
    url: `company/loadByCompanies`,
    params: {
      ...filters,
      language: language.toUpperCase(),
    },
    headers: {
      language: language.toUpperCase(),
      section: "H", 
      channel: "w", 
  },
  });

  return { result, loading, error, refetch };
};

export const useGetCompany = (id: number | string, language: string) => {
  const { data: company, loading: companyLoading, error: companyError, refetch } = CreateAxiosGetRequest<CompaniesResponse>({
    url: `company/loadCompany`,
    params: { Id: id, language },
  });

  return { company, companyLoading, companyError, refetch };
};

export const useGetCompanyTypes = () => {
  const { language } = useLanguage();
  const { data: result, loading, error, refetch } = CreateAxiosGetRequest({
    url: `systemData/loadCompanyType`,
    params: { language: language.toUpperCase(), },
    headers: {
      language: language.toUpperCase(),
      section: "H", 
      channel: "w", 
  },
  });

  return { result, loading, error, refetch };
};

export const useFetchCompanySuggestions = (searchTerm: string) => {
  const { language } = useLanguage();

  const { data, loading, error, refetch } = CreateAxiosGetRequest<CompaniesResponse>({
    url: `company/loadByCompaniesNames`,
    params: { 
      name: searchTerm,
      language: language.toUpperCase(),
     },
     headers: {
      language: language.toUpperCase(),
      section: "H", 
      channel: "w", 
  },
  });

  return { companies: data?.result || [], loading, error, refetch };
};

export const usePostCompanyClick = (params?: ClickAction) => {
  const { responseData, loading, error, refetch } = CreateAxiosPostRequest<ClickAction>({
    url: `company/companyClick`,
    data: params,
    enabled:false
  });

  return { responseData, loading, error, refetch };
};