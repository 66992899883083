import './agentCard.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { convertObjectToImageString } from '../../helpers/helpers';
import { AgentDTO } from '../../helpers/types/agent';



export default function AgentCard({ agent }: { agent: AgentDTO }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
  
      
    
    return (
        <div className="agent-card"
        onClick={() => navigate(`/agentProfile/${agent.id}`)}
        >
            <div className="img">
                <div className="company-logo-container"><img className="company-logo" src={convertObjectToImageString(agent.company.logo)} /></div>
                <img className="agent-img" src={convertObjectToImageString(agent?.image) ?? "/images/agents/1.png"} />
                <span className="name">{agent?.name ?? "Agent Name"}</span>
                <span className="position">{agent?.position ?? "Agent Position"}</span>
            </div>
            {/* <div className="devider" /> */}
            <div className="details">
                <div className="field">
                    <span className="title">{t("common.Emirate")} </span>
                    <span className="value">{agent.emairtName}</span>
                </div>
                <div className="field">
                    <span className="title">{t("common.Properties")} </span>
                    <span className="value">{agent.totalProp ?? 0}</span>
                </div>
                <div className="field">
                    <span className="title">{t("agents.Language")} </span>
                    <span className="value">
                        {agent.lang?.length > 1
                            ? `${agent.lang[0]}, +${agent.lang.length - 1}`
                            : agent.lang?.length === 1
                                ? agent.lang[0]
                                : '-'}
                    </span>
                </div>
            </div>
            <button className="open" onClick={() => navigate(`/agentProfile/${agent.id}`)}>{t('FindYourAgent.OpenProfile')}</button>
       
        </div>
    )
}